import React from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { PaginationNav } from "../common/Components"
import { Header } from "../common/Header"


export const Template = ({ content, companyId, productId = 1, currentPageId, paginationDisabled = false, ...props }) => {
  var paginationItems = [
    { title: "Dashboard", link: `${window.location.origin}/companies/${companyId}` },
    { title: "Product selling points", link: `${window.location.origin}/companies/${companyId}/${productId}/selling-points` },
    { title: "Outbound channels", link: `${window.location.origin}/companies/${companyId}/${productId}/channel-selection` },
    { title: "Content generation", link: `${window.location.origin}/companies/${companyId}/${productId}/content-generation` },
    { title: "Next iteration", link: `${window.location.origin}/companies/${companyId}/${productId}/next-iteration` },
    { title: "Performance tracking", link: `${window.location.origin}/companies/${companyId}/${productId}/performance-tracking` },
  ]

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content="width=1800, initial-scale=1.0, user-scalable=1" />
        <link rel="canonical" href={`https://versi0n.io/companies/${companyId}`} />
        <title>Workspace | version</title>
      </Helmet>

      <Header width={"1800px"} />

      <div className="container" style={{ width: "1800px", minWidth: "1800px", maxWidth: "1800px", padding: "50px" }}>
        <PaginationNav paginationItems={paginationItems} initPageId={currentPageId} paginationDisabled={paginationDisabled} />
      </div>

      <div className="container" style={{ width: "1800px", minWidth: "1800px", maxWidth: "1800px", padding: "50px", minHeight: "800px" }}>
        {content}
      </div>
    </HelmetProvider>
  )
}
