import { useState, useRef, useEffect, Fragment } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { TypeAnimation } from "react-type-animation"
import { BarChart } from "@mui/x-charts"
import { FaMagic } from "react-icons/fa"
import { Message } from "../common/Message"
import { Template } from "./Template"
import { handleGtag } from "../utils/gtag"
import { handleProduct, handleCompany } from "../../lib/.api"
import { returnSelfUserId, setItem } from "../../lib/.auth"
import "../../styles/main.scss"
var userId = returnSelfUserId()


export const Workspace = () => {
  const navigate = useNavigate(); const { companyId } = useParams();
  const [company, setCompany] = useState(null); const [paginationDisabled, setPaginationDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false); const [error, setError] = useState(null)
  useEffect(() => {
    setIsLoading(true)
    if (!company) {
      handleCompany(companyId)
        .then(res => {
          if (res.status !== 200) { setError("Server error") }
          else {
            setCompany({ ...res.data }); setItem("name", res.data["name"]); setItem("logo", res.data["logo"])
            if (res.data.products.length > 0) { setPaginationDisabled(false); }
          }
        })
        .catch(err => { setError("Something went wrong.") })
    }
    setIsLoading(false)
  }, [companyId, company])


  // address product updates
  let updatedDescription = useRef(null); let updatedAudience = useRef(null); const [selectedProductId, setSelectedProductId] = useState(0)
  const [editMode, setEditMode] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const updateProduct = (e, product) => {
    if (updatedDescription.current || updatedAudience.current) {
      setIsUpdating(true)
      handleProduct(companyId, product.id, { "description": updatedDescription.current, "audience": updatedAudience.current })
        .then(res => {
          if (res.status !== 200) { setError("Something went wrong.") }
          else { updatedDescription.current = null; updatedAudience.current = null; setEditMode(false); }
          return res.data
        })
        .then(product => { setSelectedProductId(product.id) })
        .catch(err => { setError("Something went wrong."); setEditMode(false); })
      setIsUpdating(false)
    }
    setEditMode(false); handleGtag(e, "workspace", userId)
  }

  if (error) return <Message message={error} redirectTo={null} />
  return (
    <Template
      companyId={companyId}
      productId={selectedProductId + 1}
      currentPageId={0}
      paginationDisabled={paginationDisabled}
      content={
        <Fragment>
          <nav className="navbar" role="navigation" aria-label="main navigation" style={{ minWidth: "90vw" }}>
            <div className="navbar-menu">
              <div className="navbar-start">
                <div className="navbar-item">
                  <img src={company?.logo} alt={company?.name} style={{ width: "100px", minHeight: "100px" }} />
                </div>
                <div className="navbar-item"> <p className="label">{company?.name}</p></div>
              </div>
              <div className="navbar-end">
                <div className="navbar-item">
                  {company?.members?.map((item, i) => {
                    return (
                      <p key={i} style={{ minWidth: "30px", width: "30px", maxWidth: "30px", minHeight: "30px", height: "30px", maxHeight: "30px", margin: "0 5px", color: "#fafafa", borderRadius: "30px", textAlign: "center", verticalAlign: "15px", backgroundColor: i === 0 ? "#414141" : "lightgrey" }}>
                        {isLoading ? "..." : String(item).slice(0, 2).toUpperCase()}
                      </p>
                    )
                  })}
                </div>
              </div>
            </div>
          </nav>

          <div className="container" style={{ display: "flex", justifyContent: "space-between", minWidth: "1800px" }}>
            <div className="container" style={{ minWidth: "600px", maxWidth: "600px", padding: "0px 10px" }}>
              <p className="label">Products</p>

              <div className="container" style={{ margin: "10px", padding: "10px", display: "flex", maxWidth: "600px", minWidth: "600px" }}>
                {company && company.products && company.products.length > 0
                  ?
                  company.products.map((item, i) => {
                    const { product_name, variants, description, landing_url, audience, id } = item
                    return (
                      <div key={i} className="card" style={{ margin: "10px 0", padding: "20px", width: "600px", maxWidth: "600px", minWidth: "500px" }}>
                        <div className="container" style={{ display: "flex", justifyContent: "space-between", minWidth: "100%", maxWidth: "100%" }}>
                          <p className="label">{isLoading ? "..." : `${Number(i) + 1}. ${product_name}`} </p>
                          {error && <p style={{ color: "red", fontSize: "12px" }}>{error}</p>}

                          <button
                            className={isUpdating ? "button is-primary is-loading" : "button is-primary"} style={{ marginLeft: "10px", width: "180px" }}
                            onClick={e => { updateProduct(e, item); navigate(`${id}/content-generation`); }}>
                            <FaMagic />&nbsp;Generate content
                          </button>
                        </div>

                        <div className="container" style={{ margin: "10px 20px", display: "flex", flexDirection: "column", alignItems: "center" }}>

                          <div className="container" style={{ padding: "20px 0", minWidth: "100%", maxWidth: "100%" }}>
                            <p className="label" style={{ lineHeight: 1.5 }}>Variants:</p>
                            {variants?.map(v => { return <li key={variants?.indexOf(v)}>{isLoading ? "..." : v}</li> })}
                          </div>

                          <div className="container" style={{ padding: "20px 0", minWidth: "100%", maxWidth: "100%" }}>
                            <p className="label" style={{ lineHeight: 1.5 }}>Descritpion:</p>
                            {isLoading ? <p>...</p> : editMode
                              ?
                              <textarea
                                className="textarea" rows={3} style={{ width: "500px", minWidth: "500px", maxWidth: "500px" }}
                                placeholder={description} defaultValue={description}
                                onChange={e => { e.preventDefault(); updatedDescription.current = e.target.value }}
                              />
                              :
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                <TypeAnimation
                                  sequence={[
                                    description,
                                    1000,
                                  ]}
                                  speed={100}
                                  style={{ fontSize: "14px", padding: "10px", minHeight: "100px" }}
                                  repeat={Infinity}
                                />
                                <button className="button is-small" onClick={e => { e.preventDefault(); setEditMode(true) }}>
                                  Edit
                                </button>
                              </div>
                            }
                          </div>

                          <div className="container" style={{ padding: "20px 0", minWidth: "100%", maxWidth: "100%" }}>
                            <p className="label" style={{ lineHeight: 1.5 }}>Audience:</p>
                            {isLoading ? <p>...</p> : editMode
                              ?
                              <textarea
                                className="textarea" rows={2} style={{ width: "500px", minWidth: "500px", maxWidth: "500px" }}
                                placeholder={audience} defaultValue={audience}
                                onChange={e => { e.preventDefault(); updatedAudience.current = e.target.value }}
                              />
                              :
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                <TypeAnimation
                                  sequence={[
                                    audience,
                                    1000,
                                  ]}
                                  speed={100}
                                  style={{ fontSize: "14px", padding: "10px", minHeight: "100px" }}
                                  repeat={Infinity}
                                />
                                <button className="button is-small" onClick={e => { e.preventDefault(); setEditMode(true) }}>
                                  Edit
                                </button>
                              </div>
                            }
                          </div>


                          <div className="container" style={{ padding: "20px 0", minWidth: "100%", maxWidth: "100%" }}>
                            <p className="label" style={{ lineHeight: 1.5 }}>Landing URL:</p>
                            <p>{isLoading ? "..." : landing_url}</p>
                            {/* <button className="button is-primary" disabled><FaInfoCircle />&nbsp;Analyze</button> */}
                          </div>
                        </div>

                        <button
                          className={isUpdating ? "button is-dark is-loading" : "button is-dark"} style={{ margin: "20px", width: "180px" }}
                          onClick={e => { updateProduct(e, item); navigate(`${id}/selling-points`); }}>
                          Set up other metrics
                        </button>
                      </div>
                    )
                  })
                  :
                  <p>Contact admin to add a product</p>
                }
              </div>

            </div>


            <div className="container" style={{ minWidth: "1000px", maxWidth: "1000px", padding: "0px 10px" }}>
              <p className="label">Performance summary</p>

              <p>Facebook Ad account ID: {isLoading ? "..." : company?.ad_id_fb}</p>
              {/* <p>Google Ad account ID: 81429695</p> */}

              <div className="container" style={{ maxWidth: "900px", minWidth: "900px", width: "900px", padding: "50px 50px 30px 50px", marginLeft: "30px" }}>

                <p className="label">Start date: {company?.products?.length > 0 && "July 2, 2024"}</p>
                <p className="label">Total budget spent: {company?.products?.length > 0 && `RM ${Math.round(145.61 + 31.34, 0)}`}</p>
                {company?.products?.length > 0
                  ?
                  <BarChart
                    style={{ margin: "20px 0", fontSize: "16px" }}
                    title="Facebook/Event space"
                    series={[
                      { label: "Imp", data: [2856, 648, 338 + 2668, 1145, 1759], yAxisKey: "1", },
                      { label: "Reach", data: [1406, 183, 1684 + 338, 757, 640], yAxisKey: "2", },
                      { label: "LP views", data: [0, 0, 0, 0, 0], yAxisKey: "3", },
                      { label: "Form submit", data: [0, 0, 0, 0, 0], color: "#fc6685", yAxisKey: "4", },
                      { label: "(R) Budget spent (RM)", data: [47.16, 12.78, 1.94 + 52.44, 31.7, 31.24], yAxisKey: "rightAxis" },
                    ]}
                    xAxis={[{ data: ["July 1", "July 8", "July 15", "July 22", "July 29"], scaleType: "band", label: "Week" }]}
                    yAxis={[
                      { id: "1", max: 4000 }, { id: "2", max: 4000 }, { id: "3", max: 4000 }, { id: "4", max: 4000 },
                      { id: "rightAxis", max: 100 },
                    ]}
                    rightAxis="rightAxis"
                    height={400}
                    margin={{ top: 100, bottom: 100, left: 50, right: 50 }}
                  />
                  :
                  <BarChart
                    style={{ margin: "20px 0", fontSize: "16px" }}
                    title="Facebook/Event space"
                    series={[
                      { label: "Imp", data: [0, 0, 0, 0, 0], yAxisKey: "1", },
                      { label: "Reach", data: [0, 0, 0, 0, 0], yAxisKey: "2", },
                      { label: "LP views", data: [0, 0, 0, 0, 0], yAxisKey: "3", },
                      { label: "Form submit", data: [0, 0, 0, 0, 0], color: "#fc6685", yAxisKey: "4", },
                      { label: "(R) Budget spent (RM)", data: [0, 0, 0, 0, 0], yAxisKey: "rightAxis" },
                    ]}
                    xAxis={[{ data: ["1", "2", "3", "4", "5"], scaleType: "band", label: "Week" }]}
                    yAxis={[
                      { id: "1", max: 4000 }, { id: "2", max: 4000 }, { id: "3", max: 4000 }, { id: "4", max: 4000 },
                      { id: "rightAxis", max: 100 },
                    ]}
                    rightAxis="rightAxis"
                    height={400}
                    margin={{ top: 100, bottom: 100, left: 50, right: 50 }}
                  />
                }


                {company?.products?.length > 0 &&
                  <>
                    <p className="label">Ref. base data</p>
                    <iframe
                      title="performance tracking"
                      src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQQktNkgucAY_I0LQQiVTPJFqzb7fVEnsNSD-YkItLbGG2aLt5VpCNYKjxi97tbAjuAq4QTBl0CqI1m/pubhtml?gid=48897150&single=true"
                      width={"800px"}
                      height={"300px"}
                    />
                  </>
                }
              </div>
            </div>

          </div>
        </Fragment>
      }
    />
  )
}