import { useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"


export const Redirect = (redirectUrl, state, timeout) => {
  let time = useRef()
  const navigate = useNavigate()
  useEffect(() => {
    const redirect = () => navigate(redirectUrl, { state: { ...state } })
    time.current = setTimeout(redirect, timeout)
  }, [navigate, redirectUrl, timeout, state])

  return () => { clearTimeout(time.current) }
}
