import React from "react"
import { createRoot } from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import "../src/styles/main.scss"

const root = createRoot(document.getElementById("root"))
const path = window.location.pathname
const componentToRender = path === "/playground" ? "playground" : "workspace"
var mobileMode = Boolean(window.innerWidth <= 430)
var tabletMode = Boolean(window.innerWidth <= 800)


root.render(<App componentToRender={componentToRender} mobileMode={mobileMode} tabletMode={tabletMode} />)
reportWebVitals()
