import { useState, memo } from "react"
import { handleGtag } from "../utils/gtag"
import { returnSelfUserId, logout } from "../../lib/.auth"
import "../../styles/main.scss"
var userId = returnSelfUserId()
// var companyId = getItem("companyId")
const handleLogout = e => {
  logout(); window.scrollTo({ top: 0, behavior: 'smooth' }); handleGtag(e, "header", userId)
}


export const Header = memo(({ width = "1000px" }) => {
  return (
    <div style={{ maxWidth: width, minWidth: width, width: width, margin: "auto" }}>
      <nav className="navbar" style={{ display: "flex", maxWidth: width, minWidth: width, width: width, }}>

        <div className="navbar-start" style={{ display: "flex" }}>
          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/">
            {/* <img
              src="https://res.cloudinary.com/dfeirxlea/image/upload/t_icon-crop-w-360-h-150/v1728219760/pj_m_home/j80bjzkwuvtethz1dybf.png"
              alt="Home" height="40" width="100%" style={{ minHeight: "40px" }}
            /> */}
            Home
          </a>
          <a className="navbar-item" href="/#how-it-works" onClick={e => handleGtag(e, "header", userId)}>
            How It Works
          </a>

          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link" href="/#use-cases" onClick={e => handleGtag(e, "header", userId)}>
              Use Cases
            </a>

            <div className="navbar-dropdown">
              <a className="navbar-item" href="/#use-cases" onClick={e => handleGtag(e, "header", userId)}>
                Upsell, Cross-sell
              </a>
              <a className="navbar-item" href="/#use-cases" onClick={e => handleGtag(e, "header", userId)}>
                Lead Generation
              </a>
              <a className="navbar-item" href="/#use-cases" onClick={e => handleGtag(e, "header", userId)}>
                Churn Prevention
              </a>
            </div>
          </div>

          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://chief-oxygen-8a2.notion.site/API-Documentation-v1-f1b706e5d89b48bcb6044f6e48bf1150" target=" _blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
            API Documentation
          </a>
        </div>

        <div className="navbar-end" style={{ display: "flex", marginLeft: "auto" }}>
          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/playground" onClick={e => handleGtag(e, "header", userId)}>
            Playground
          </a>
          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/#custom-demo" onClick={e => handleGtag(e, "header", userId)}>
            Custom Demo
          </a>
          {/* <a className="navbar-item" style={{ display: "flex", alignItems: "center" }}
            href={userId && companyId ? `/companies/${companyId}` : "/sign-up"}
            onClick={e => handleGtag(e, "header", userId)}
          >
            Your Workspace&nbsp;<span style={{ fontSize: "24px" }}>🦄</span>
          </a> */}
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link" href="/" onClick={e => handleGtag(e, "header", userId)}>
              About Us
            </a>
            <div className="navbar-dropdown">
              <a className="navbar-item" href="https://kuriko.vercel.app" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
                Founder
              </a>
              <a className="navbar-item" href="/#contact-us" onClick={e => handleGtag(e, "header", userId)}>
                Contact Us
              </a>
            </div>
          </div>

          {userId &&
            <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleLogout(e)}>
              Logout
            </a>
          }
        </div>

      </nav>
    </div>
  )
})



export const MobileHeader = memo(() => {
  const [isOpenBurger, setIsOpenBurger] = useState(false)

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <div className="navbar-start" style={{ display: "flex" }}>
          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/">
            {/* <img src="https://res.cloudinary.com/dfeirxlea/image/upload/v1720951664/pj_m_test/okhcptb8eukhwjwq4u5q.png"
              alt="versi0n.io" height="50" width="178" style={{ minHeight: "50px" }} /> */}
          </a>
        </div>

        <div className={isOpenBurger ? "navbar-burger is-active" : "navbar-burger"} onClick={() => setIsOpenBurger(!isOpenBurger)} style={{ color: "#191b1b" }}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>

      {isOpenBurger &&
        <div className="navbar-dropdown" id="burger-menu">
          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/#how-it-works" onClick={e => handleGtag(e, "header", userId)}>
            How It Works
          </a>
          <a className="navbar-item" style={{ display: "flex", flexDirection: "column" }} href="/#use-cases" onClick={e => handleGtag(e, "header", userId)}>
            Use Cases
            <ul style={{ marginLeft: "30px" }}>
              <li>Upsell, Cross-sell</li>
              <li>Churn Prevention</li>
              <li>Lead Generation</li>
            </ul>
          </a>
          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="https://chief-oxygen-8a2.notion.site/API-Documentation-v1-f1b706e5d89b48bcb6044f6e48bf1150" target=" _blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
            API Documentation
          </a>
          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/playground" onClick={e => handleGtag(e, "header", userId)}>
            Playground
          </a>
          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/#custom-demo" onClick={e => handleGtag(e, "header", userId)}>
            Custom Demo
          </a>
          <hr />
          <a className="navbar-item" href="https://kuriko.vercel.app" target="_blank" rel="noreferrer" onClick={e => handleGtag(e, "header", userId)}>
            About Us
          </a>
          <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/#contact-us" onClick={e => handleGtag(e, "header", userId)}>
            Contact Us
          </a>

          {/* <a className="navbar-item" style={{ display: "flex", alignItems: "center" }}
            href={userId && companyId ? `/companies/${companyId}` : "/sign-up"}
            onClick={e => handleGtag(e, "header", userId)}
          >
            Your Workspace&nbsp;<span style={{ fontSize: "24px" }}>🦄</span>
          </a> */}
          {userId &&
            <a className="navbar-item" style={{ display: "flex", alignItems: "center" }} href="/" onClick={e => handleLogout(e)}>
              Logout
            </a>
          }
        </div>
      }
    </nav>
  )
})