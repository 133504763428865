import { useState, memo } from "react"
import { MdKeyboardDoubleArrowUp, MdKeyboardArrowDown } from "react-icons/md"
import { FaArrowRight } from "react-icons/fa"


export const BackToTop = memo(({ padding = "50px", ...props }) => {
  return (
    <div style={{
      position: "sticky",
      bottom: 0,
      padding: padding,
      width: "100%",
      zIndex: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
      backgroundColor: "transparent",
    }}>
      <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth", })} style={{ color: "#414141" }}>
        Top&nbsp;<MdKeyboardDoubleArrowUp />
      </button>
    </div >
  )
})


export const DropdownMenu = ({
  options = Array({ name: null, description: null, }),
  defaultValue = null,
  width = "300px",
  index, setIndex,
  disabled = false,
  styleProps = {},
  disabledOptions = [], disabledMessage = null,
  ...props
}) => {
  const [displayDropdownMenu, setDisplayDropdownMenu] = useState(false)

  return (
    <div
      className={displayDropdownMenu ? "dropdown is-active" : "dropdown"}
      style={{ width: width, maxWidth: width, minWidth: width, ...styleProps, }}>
      <div className="dropdown-trigger">
        <button className="button" aria-disabled={disabled} aria-controls="dropdown-menu" style={{ width: width, maxWidth: width, minWidth: width }} aria-haspopup="true" onClick={e => { e.preventDefault(); setDisplayDropdownMenu(!displayDropdownMenu) }} >
          {index !== null ? options[index]["name"] : defaultValue ? defaultValue : <>Select&nbsp;<MdKeyboardArrowDown /></>}
        </button>
      </div>

      {displayDropdownMenu &&
        <div className="dropdown-menu" id="dropdown-menu" role="menu" style={{ width: "100%" }}>
          <div className="dropdown-content" style={{ width: "100%" }}>
            {
              options.map((item, i) => {
                var disabled = Boolean(disabledOptions.length > 0 && disabledOptions.find(item => item === i))
                return (
                  <button
                    key={i} disabled={disabled} className={i === index ? "dropdown-item is-active" : disabled ? "dropdown-item has-background-grey-lighter" : "dropdown-item"}
                    onClick={e => { e.preventDefault(); setIndex(i); setDisplayDropdownMenu(false); }}
                  >
                    {disabled && disabledMessage ? `${item.name} - ${disabledMessage}` : item.name}
                  </button>
                )
              })
            }
          </div>
        </div>
      }
    </div>
  )
}


export const Tag = ({ category, item, color = "is-primary is-light", button = null, width = "360px", ...props }) => {
  return (
    <span className={`tag ${color} is-medium`} style={{ margin: "5px" }}>
      {item.toLowerCase()}
      {button ? button : <button className="delete is-small"></button>}
    </span>
  )
}


export const Select = ({ options = [], currentSelectedItem, width = "350px", ...props }) => {
  return (
    <select
      style={{ width: width, minWidth: width, maxWidth: width }}
      onClick={e => {
        if (options[e.target.selectedIndex] !== undefined) {
          currentSelectedItem.current = options[e.target.selectedIndex]["value"]; if (props.callBack) { props.callBack() }
        }
      }}
    >
      {options.map((item, i) => { return (<option key={i}>{item["title"]}</option>) })}
    </select >
  )
}


export const NewsBar = ({ newsTitle, link, fontSize = "16px" }) => {
  return (
    <div style={{ border: "solid 1px grey", borderRadius: "20px", padding: "5px 10px" }}>
      <p style={{ color: "#414141", fontSize }}>
        📣 {newsTitle} {link &&
          <a href={link} aria-label="Explore" target="_blank" rel="noreferrer" style={{ color: "#414141" }}>
            <FaArrowRight />
          </a>
        }
      </p>
    </div>
  )
}