import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { SecureRouteCompany } from "./App.Route"
import { Home, MobileHome } from "./components/Home"
import { Playground } from "./components/Playground"
import { Workspace } from "./components/company/Workspace"
import { SellingPoints, ChannelSelection, AdDirections, NextIteration, PerformanceTracking } from "./components/Demo.Worq"
import { FbAuth } from "./components/auth/FbAuth"
import { SignUp, SignIn } from "./components/auth/Auth"
import { Message } from "./components/common/Message"


export const App = ({ componentToRender, mobileMode, tabletMode }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Message message="Page not found" />} />
        <Route exact path="/" element={tabletMode ? <MobileHome /> : <Home />} />
        <Route exact path="/sign-up" element={<SignUp />} />
        <Route exact path="/sign-in" element={<SignIn />} />

        {componentToRender === "playground" &&
          <Route path="playground">
            <Route path="" element={<Playground />} />
          </Route>
        }

        {componentToRender === "workspace" &&
          <Route path="companies/:companyId">
            <Route path="" element={<SecureRouteCompany><Workspace /></SecureRouteCompany>} />
            <Route path=":productId">
              <Route path="fb-auth/:fbAuthCode" element={<FbAuth />} />
              <Route path="selling-points" element={<SecureRouteCompany><SellingPoints /></SecureRouteCompany>} />
              <Route path="channel-selection" element={<SecureRouteCompany><ChannelSelection /></SecureRouteCompany>} />
              <Route path="content-generation" element={<SecureRouteCompany><AdDirections /></SecureRouteCompany>} />
              <Route path="next-iteration" element={<SecureRouteCompany><NextIteration /></SecureRouteCompany>} />
              <Route path="performance-tracking" element={<SecureRouteCompany><PerformanceTracking /></SecureRouteCompany>} />
            </Route>
          </Route>
        }
      </Routes>
    </BrowserRouter >
  )
}
