import React from "react"
import { useParams } from "react-router-dom"
import { Redirect } from "../utils/Redirect"

export const FbAuth = () => {
  const { companyId, productId, fbAuthCode } = useParams()
  Redirect(`/companies/${companyId}/${productId}/content-generation`, { state: { fbAuthCode } })
  return (
    <div className="container">
      <p>Redirecting</p>
    </div>
  )
}