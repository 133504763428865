import { useState, useRef } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { TypeAnimation } from "react-type-animation"
import { InlineWidget } from "react-calendly"
import { FaMagic } from "react-icons/fa"
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md"
import { Header } from "./common/Header"
import { TextAgentInterface } from "./common/TextAgentInterface"
import { Loading } from "./common/Message"
import { DropdownMenu } from "./common/Elements"
import { ContactForm, FacebookAd } from "./common/Components"
import { ClientLandingPage } from "./ClientLandingPage"
import { UploadWidget } from "./utils/UploadWidget"
import { handleGtag } from "./utils/gtag"
import { transformImage } from "../lib/.api"
import "../styles/main.scss"


var demoUrl = "https://www.coinbase.com"; var demoBrand = "Coinbase";
var demoBrandLogo = "https://res.cloudinary.com/dfeirxlea/image/upload/v1722279907/pj_m_playground_coinbase/fboleezijxknezllqibd.webp"
var productList = [{ name: "Marketplace", description: "a platform that allows users to buy, sell, and manage a wide range of cryptocurrencies with ease. It offers a user-friendly interface, robust security measures, and access to market data to help users make informed trading decisions." }, { name: "Wallet", description: "a secure digital wallet that allows you to store, send, and receive various cryptocurrencies. It provides access to decentralized applications (dApps), enabling users to participate in the DeFi ecosystem. With features like NFT support and advanced security options, the Coinbase Wallet offers a comprehensive solution for managing your crypto assets." }, { name: "Card", description: "a debit card linked to your cryptocurrency holdings. It enables you to spend your crypto anywhere that accepts Visa, converting your digital assets into fiat currency at the point of sale. This card offers a seamless bridge between the crypto and traditional financial worlds." }, { name: "Learning rewards", description: "an educational program that incentivizes users to learn about cryptocurrencies. By completing interactive lessons and quizzes, users can earn rewards in the form of cryptocurrency. This program aims to foster financial literacy and encourage crypto adoption." }, { name: "Developers solutions", description: "a suite of tools and APIs for developers to build applications on top of the Coinbase platform. It offers access to market data, wallet functionalities, and payment processing capabilities. With Coinbase Developers, developers can create innovative crypto-based products and services." },];
var directions = [{ name: "Case 1", description: "Highlight security and control in comparison with traditional fintech services." }, { name: "Case 2", description: "Promote DeFi and NFT opportunities targeting users interetsed in digital collectibles." }, { name: "Case 3", description: "Educate about crypto wallet basics targeting new user segments." }, { name: "Case 4", description: "Leverage existing coinbase user base." }, { name: "Case 5", description: "Partner with influencers and crypto communities targeting core users." }, { name: "Input direction", description: null }]


const LandingUrl = ({ url = demoUrl, disabled = true, ...props }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [displayProduct, setDisplayProduct] = useState(true)
  const time = () => { setTimeout(() => { setDisplayProduct(true); setIsFetching(false) }, 3500); return () => clearTimeout() }

  return (
    <form className="box" style={{ width: "500px", padding: "20px", margin: "50px 0 50px 50px" }}>
      <p className="label">Landing URL</p>

      <div className="container" style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
        <input className="input" type="url" name="url" placeholder={url} disabled={disabled} value={disabled ? url : undefined} style={{ marginRight: "10px" }} />
        <button
          className={isFetching ? "button is-primary is-loading" : "button is-primary"}
          onClick={e => { e.preventDefault(); setIsFetching(true); handleGtag(e, "Demo"); time(); }}
          disabled={displayProduct}
        >
          Analyze
        </button>
      </div>
      {displayProduct && props.children}
    </form>
  )
}
const ProductInfo = props => {
  const [index, setIndex] = useState(null)
  const [disabledProductDropdown, setDisabledProductDropdown] = useState(false)
  const [error, setError] = useState(null)

  return (
    <div className="container">

      <div className="container" style={{ display: "flex", margin: "20px 0 10px 0", }}>
        <div className="control" style={{ width: "230px" }}>
          <p className="label">Product</p>

          <div className="container" style={{ display: "flex" }}>
            <label>
              <input
                className="input" type="text" disabled value={demoBrand} name="input brand name"
                style={{ marginRight: "10px", width: "150px", maxWidth: "150px", minWidth: "150px" }}
              />
            </label>
            <DropdownMenu options={productList} index={index} setIndex={setIndex} disabled={disabledProductDropdown} defaultValue={props.product} />
          </div>
        </div>
      </div>

      <div className="control">
        {props.description
          ? <TypeAnimation
            sequence={[
              `This product is ${props.description}`,
              1000
            ]}
            speed={100}
            style={{
              fontSize: "14px", padding: "10px", minHeight: "80px", border: "solid 1px #dedede", borderRadius: "10px", display: "block",
              wordBreak: "break-all",
            }}
            repeat={3}
          />
          : index !== null && !disabledProductDropdown
            ? <TypeAnimation
              sequence={[
                `This product is ${productList[index]["description"]}`,
                1000
              ]}
              speed={100}
              style={{
                fontSize: "14px", padding: "10px", minHeight: "80px", border: "solid 1px #dedede", borderRadius: "10px", display: "block",
                wordBreak: "break-all",
              }}
              repeat={3}
            />
            : disabledProductDropdown
              ? <textarea
                className="textarea" disabled row={7}
                style={{ fontSize: "14px", padding: "10px", border: "solid 1px #dedede", borderRadius: "10px" }}
                defaultValue={`This product is ${productList[index]["description"]}`}
              />
              : <div style={{ minHeight: "80px" }}></div>
        }
      </div>
      {index !== null && !disabledProductDropdown &&
        <button
          className="button is-dark" style={{ marginTop: "10px", width: "150px" }}
          disabled={index === null}
          onClick={e => {
            e.preventDefault(); handleGtag(e, "playground"); setDisabledProductDropdown(true);
            if (index === null) setError("Select a product.")
            else {
              props.setProduct(productList[index]["name"]); props.setDescription(productList[index]["description"])
              props.setDisplayDirection(true); e.target.disabled = true;
            }
          }}
        >
          Next
        </button>
      }
      {error && <p style={{ color: "red", fontSize: "16px" }}>{error}</p>}
    </div >
  )
}
const Direction = props => {
  let newDirection = useRef(null);
  const [index, setIndex] = useState(null);
  const [dropdonwDisabled, setDropdownDisabled] = useState(false)
  const [error, setError] = useState(null)

  return (
    <form className="box" style={{ width: "500px", padding: "20px", margin: "5px 0 5px 50px" }}>

      <p className="label">Lead Generation</p>
      <div className="container" style={{ display: "flex", alignItems: "center", margin: "10px 0 20px 0" }}>
        {props.direction ? <p>{props.direction}</p>
          : <>
            <DropdownMenu
              options={directions} width="150px" index={index} setIndex={setIndex} disabled={dropdonwDisabled}
            />
            {index !== null && directions[index]["description"]
              ? <p style={{ margin: "0 10px", width: "400px", fontSize: "14px" }}>{directions[index]["description"]}</p>
              : index !== null && newDirection.current
                ? <p style={{ margin: "0 10px", width: "400px", fontSize: "14px" }}>{newDirection.current}</p>
                : <input className="input" type="text" name="input" style={{ margin: "0 10px", width: "400px" }} placeholder="Create" onChange={e => { newDirection.current = e.target.value }} />
            }
          </>
        }
      </div>

      <button
        className="button is-dark" style={{ marginTop: "10px", width: "150px" }}
        onClick={e => {
          e.preventDefault(); handleGtag(e, "playground"); setDropdownDisabled(true);
          const direction = newDirection.current !== null ? newDirection.current : index !== null ? directions[index]["description"] : null
          if (direction === null) { setError("Set a direction."); setDropdownDisabled(false); }
          else { props.setDirection(direction); props.setDisplayTextAgent(true); props.setDisplayExp(false); }
        }}>
        Start iteration
      </button>
      {error && <p style={{ color: "red", fontSize: "16px" }}>{error}</p>}
    </form>
  )
}

const Launchrer = props => {
  const { disableLauncher } = props
  const [displayShipModal, setDisplayShipModal] = useState(false)
  const [displayFinetuneModal, setDisplayFinetuneModal] = useState(false)

  return (
    <div style={{ width: "450px" }}>
      <div style={{ width: "450px" }}>
        {/* <p className="label">Launcher</p> */}
        <div className="container" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <button
            className="button is-dark" style={{ marginRight: "10px" }}
            disabled={disableLauncher}
            onClick={e => { e.preventDefault(); handleGtag(e, "playground"); setDisplayShipModal(true) }}
          >
            Ship this content
          </button>
          <button className="button is-primary" style={{ marginRight: "10px" }}
            disabled={disableLauncher}
            onClick={e => { e.preventDefault(); handleGtag(e, "playground"); setDisplayFinetuneModal(true); }}>
            <FaMagic />&nbsp;Fine-tune the agent
          </button>
        </div>
        {/* {winnerTextId !== null &&
          <p style={{ fontSize: "14px", color: "#B9005B", width: "400px" }}>Winner: {textVariations[winnerTextId]}</p>} */}
      </div>

      <div className={displayShipModal ? "modal is-active" : "modal"} style={{ backgroundSize: "cover" }}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Sign up</p>
            <button className="delete" aria-label="close" onClick={() => { setDisplayShipModal(false); }}></button>
          </header>
          <section className="modal-card-body" style={{ width: "100%" }}>
            <ContactForm width="550px" height="100px" rows="1" defaultMessage={"Claim access token"} />
          </section>
          <footer></footer>
        </div>
      </div>

      <div className={displayFinetuneModal ? "modal is-active" : "modal"} style={{ backgroundSize: "cover" }}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Fine-tune the agent performance</p>
            <button className="delete" aria-label="close" onClick={() => { setDisplayFinetuneModal(false); }}></button>
          </header>
          <section className="modal-card-body" style={{ width: "100%" }}>
            <p className="label">Learnings added to the agent</p>
            <textarea className="textarea" rows={3} />
            <br />
            <p className="label">Upload historical performance .csv</p>
            <UploadWidget successMessage="We have staretd the training process. Contact us for the outcome." />
          </section>
          <footer className="modal-card-foot">
            <button className="button" onClick={() => setDisplayFinetuneModal(false)}>OK</button>
          </footer>
        </div>
      </div>
    </div >
  )
}

export const ImageAgentInterface = props => {
  const { winnerText, setSelectedImageSrc } = props
  const [isGenerating, setIsGenerating] = useState(false)
  const [originalImageSrc, setOriginalImageSrc] = useState(null); const [error, setError] = useState(null)
  const [imageVariations, setImageVariations] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const handleImageVariation = e => {
    e.preventDefault(); handleGtag(e, "playground"); setIsGenerating(true);

    if (imageVariations.length === 0) {
      const formData = { originalImageSrc, winnerText }
      transformImage(formData)
        .then(res => {
          if (res.status !== 200) { setError("Something went wrong."); }
          else { setImageVariations([...res.data["images"]]);; setDisableButton(true) }
        })
        .catch(err => { setError(originalImageSrc ? "Something went wrong." : "Upload the image."); })
    }
    setIsGenerating(false)
  }

  return (
    <div className="container" style={{ display: "flex", width: "1200px", minWidth: "1200px", maxWidth: "1200px" }}>
      <form className="box" style={{ width: "1000px", minHeight: "500px", maxHeight: "1100px", padding: "70px" }}>

        <p className="label">Image Agent - Inference API</p>

        <div className="radios" style={{ display: "flex", alignItems: "center", margin: "30px 10px 10px 10px", }}>
          <UploadWidget message={"Base image (jpg, png)"} setImage={setOriginalImageSrc} />
          <button
            className={isGenerating ? "button is-primary is-loading" : "button is-primary"} style={{ marginLeft: "auto" }}
            onClick={e => handleImageVariation(e)} disabled={disableButton}
          >
            Generate variations
          </button>
        </div>
        {error && <p style={{ color: "red", fontSize: "16px" }}>{error}</p>}

        <div className="container" style={{ margin: "20px 0 10px 0", }}>
          <div className="container has-background-white-bis" style={{ padding: "10px", width: "900px" }}>

            {originalImageSrc !== null
              ? <img src={originalImageSrc} alt="original" style={{ width: "auto", height: "300px" }} />
              : <p style={{ color: "grey" }}>( - )</p>}

            <hr />
            <div className="container" style={{ display: "flex", flexWrap: "wrap", maxWidth: "900px", alignItems: "center" }}>
              {isGenerating
                ? <Loading />
                : imageVariations.length > 0 && imageVariations.map((item, i) => {
                  return (
                    <label className="checkbox" style={{ display: "flex" }}>
                      <input type="checkbox" name="kv" onClick={() => setSelectedImageSrc(item)} />
                      <img key={i} src={item} alt="ad creative" style={{ width: "auto", height: "150px", margin: "10px" }} />
                    </label>
                  )
                })
              }
            </div>
          </div>
        </div>
      </form >
    </div>
  )
}

export const Playground = () => {
  const [displayBookingModal, setDisplayBookingModal] = useState(false)
  const [displayExp, setDisplayExp] = useState(true); const [displayDirection, setDisplayDirection] = useState(false)
  const [displayTextAgent, setDisplayTextAgent] = useState(false); const [displayImageAgent, setDisplayImageAgent] = useState(false)
  const [disableLauncher, setDisableLauncher] = useState(false)
  const [product, setProduct] = useState(null); const [description, setDescription] = useState(null)
  const [direction, setDirection] = useState(null)
  const [selectedImageSrc, setSelectedImageSrc] = useState(null)
  const [winnerText, setWinnerText] = useState(null)

  const handleImageAgent = e => {
    e.preventDefault(); handleGtag(e, "text agent interface");
    setDisableLauncher(false); setDisplayTextAgent(false); setDisplayImageAgent(true);
  }

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content="width=1800, initial-scale=1.0, user-scalable=1" />
        <link rel="canonical" href="https://versi0n.io/playground" />
        <title>Playground | version</title>
      </Helmet>

      <Header width={"1800px"} />

      <div className="container" style={{ height: "1200px", minHeight: "1200px", maxHeight: "1200px", minWidth: "1800px", maxWidth: "1800px", width: "1800px", display: "flex", margin: 0, overflow: "hidden" }}>
        <div style={{ position: "absolute", top: 0, right: "250px", zIndex: 5, display: displayImageAgent ? undefined : "none" }}>
          <Launchrer style={{ zIndex: 5 }} disableLauncher={disableLauncher} />
        </div>
        <button className="button" style={{ position: "absolute", right: "50px", top: "0px", }}
          onClick={e => { e.preventDefault(); handleGtag(e, "playground"); setDisplayBookingModal(true); }}>
          👉 Request a custom demo
        </button>

        {displayExp
          ? <MdKeyboardDoubleArrowLeft style={{ position: "absolute", left: "20px", top: "500px", zIndex: 3 }} onClick={() => setDisplayExp(false)} />
          : <MdKeyboardDoubleArrowRight style={{ position: "absolute", left: "20px", top: "500px", zIndex: 3 }} onClick={() => setDisplayExp(true)} />
        }

        {displayExp &&
          <div className="container" style={{ display: "flex", flexDirection: "column" }}>
            <LandingUrl product={product}>
              <ProductInfo
                setDisplayDirection={setDisplayDirection}
                product={product} setProduct={setProduct}
                description={description} setDescription={setDescription}
              />
            </LandingUrl>

            {displayDirection &&
              <Direction
                setDisplayTextAgent={setDisplayTextAgent} setDisplayExp={setDisplayExp}
                direction={direction} setDirection={setDirection}
              />
            }
          </div>
        }

        {displayTextAgent &&
          <div style={{ display: "flex", flexDirection: "column", width: "1100px", maxHeight: "1000px", padding: "50px" }}>
            <TextAgentInterface
              productName={product} description={description} direction={direction} setDisableLauncher={setDisableLauncher}
              setWinnerText={setWinnerText}
            />
            <div style={{ padding: "30px 0 10px 70px" }}>
              <button className="button is-primary" onClick={e => handleImageAgent(e)}>
                Launch image agent
              </button>
            </div>
          </div>
        }

        {displayImageAgent &&
          <div style={{ display: "flex", flexDirection: "column", width: "1100px", maxHeight: "1000px", padding: "50px" }}>
            <ImageAgentInterface
              winnerText={winnerText} setSelectedImageSrc={setSelectedImageSrc}
            />
          </div>
        }

        <div className="has-background-white-ter" style={{ margin: "50px 0 auto 0", width: "1200px", minWidth: "1200px", padding: "100px 0 100px 50px", display: "flex", overflow: "hidden", height: "1000px" }}>
          <FacebookAd
            width="300px" landingUrl={demoUrl} brandName={demoBrand} brandLogo={demoBrandLogo}
            keyMessage={winnerText ? winnerText : null}
            imgSrc={selectedImageSrc}
          />
          <ClientLandingPage
            style={{ width: "800px", maxWidth: "800px", minWidth: "800px", height: "800px", marginLeft: "30px", zIndex: "1", }}
            newH1={winnerText ? winnerText : null}
            newH2={winnerText ? winnerText : null}
            imgSrc={selectedImageSrc} imgIndex={1}
          />
        </div>


        <div className={displayBookingModal ? "modal is-active" : "modal"} style={{ backgroundSize: "cover" }}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Request a custom demo</p>
              <button className="delete" aria-label="close" onClick={() => { setDisplayBookingModal(false); }}></button>
            </header>
            <section className="modal-card-body" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <InlineWidget
                url="https://calendly.com/kuriko-i/custom-demo?hide_event_type_details=1&hide_gdpr_banner=1"
                styles={{ marginTop: "30px", width: "500px", height: "600px" }}
              />
            </section>
            <footer></footer>
          </div>
        </div>

      </div>
    </HelmetProvider >
  )
}