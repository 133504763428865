import { useState, useEffect } from "react"
import { fetchUrl } from "../lib/.api"
import { Loading } from "./common/Message"
import { modify } from "./utils/lp"


export const ClientLandingPage = (
  {
    url = "https://www.coinbase.com",
    newH1 = null,
    newH2 = null,
    imgSrc = null,
    imgIndex = null,
    style = {},
    ...props
  }
) => {
  const [html, setHtml] = useState(null); const [isLoading, setIsLoading] = useState(false); const [error, setError] = useState(null)

  useEffect(() => {
    if (html === null) {
      setIsLoading(true)
      fetchUrl(url)
        .then(res => {
          if (res.status !== 200) { setError("Error") }
          else { setHtml(res.data.content) }
        })
        .catch(err => {
          if (window.location.hostname === "localhost") console.log(err);
          setError("Error")
        })
      setIsLoading(false)
    }
  }, [html, url])

  useEffect(() => {
    if (html !== null && (newH1 || newH2 || imgSrc)) {
      var parser = new DOMParser(); var doc = parser.parseFromString(html, 'text/html')
      var modifiedDoc = modify(doc, newH1, newH2, imgSrc, imgIndex)
      var s = new XMLSerializer()
      var newXmlStr = s.serializeToString(modifiedDoc)
      setHtml(newXmlStr)
    }
  }, [newH1, newH2, imgSrc, imgIndex, html])

  if (error) return;
  return (
    <div style={{ ...style }}>
      {/* {error && <Message message={error} redirectTo={null} />} */}
      {isLoading && <Loading />}
      {html && <html style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: html }} />}
    </div>
  )
}