import { useParams } from "react-router-dom"
import { Message } from "./components/common/Message"


export const SecureRouteCompany = props => {
  const { companyId } = useParams()
  if (window.localStorage.getItem("companyId") === companyId) return props.children
  else return <Message message="Access denied" redirectTo="/sign-in" />
}


export const SecureRouteOwner = props => {
  const { username } = useParams()
  if (window.localStorage.getItem("username") === username) return props.children
  else return <Message message="Access denied" redirectTo="/" />
}
