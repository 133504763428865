import { useState, Suspense, Fragment } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { InlineWidget } from "react-calendly"
import { handleGtag } from "./utils/gtag"
import { Header, MobileHeader } from "./common/Header"
import { Footer, MobileFooter } from "./common/Footer"
import { ContactForm } from "./common/Components"
import { BackToTop, NewsBar } from "./common/Elements"
import { Loading } from "./common/Message"
import { returnSelfUserId } from "../lib/.auth"
import "../styles/main.scss"
var userId = returnSelfUserId()
var domain = window.location.origin
var carouselCompanies = [
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo-155/v1721811096/pj_m_test/i2nbnlnxdcszpsqdohca.png",
    alt: "walt disney", height: "30", width: "200"
  },
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo/v1721811097/pj_m_test/drcmvcf68glnyn0b39m0.png",
    alt: "meta", height: "70", width: "125"
  },
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo-155/v1721811096/pj_m_test/s3e3q0xoayabroccd8di.png",
    alt: "inmobi", height: "20", width: "136"
  },
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo-155/v1721811095/pj_m_test/n50q9db15ulzzcozdi6w.png",
    alt: "mineski", height: "58", width: "150"
  },
  {
    logo: "https://res.cloudinary.com/dfeirxlea/image/upload/t_home-small-logo-155/v1721811096/pj_m_test/dgsqh1lqnwghohaprjch.png",
    alt: "insead", height: "71", width: "155"
  },
]
var useCases = [
  {
    backgroundImg: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728401906/pj_m_home/tmn4kidjiik9wkrvzxjl.png",
    backgroundImgSelected: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728383839/pj_m_home/cbinkonfxjugq8k9sz5t.png",
    title: "Upsell / Cross-sell",
    bottomPosition: "120px",
  },
  {
    backgroundImg: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728401661/pj_m_home/mbotk1uyvkyqt2hl3agd.png",
    backgroundImgSelected: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728383839/pj_m_home/bkju706ouvxwc6qkevrd.png",
    title: "Churn Prevention",
    bottomPosition: "110px"

  },
  {
    backgroundImg: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728401907/pj_m_home/z6cpsncl4pmwghrwknek.png",
    backgroundImgSelected: "https://res.cloudinary.com/dfeirxlea/image/upload/t_w240_h_auto/v1728383838/pj_m_home/foowahttxyjnus8zlmvp.png",
    title: "Lead Generation",
    bottomPosition: "130px"
  }
]
var faqList = [
  {
    q: "Which lead gen/engagment channels do you support?",
    a: "We support retention channels (Emails, push notification, SMS, WhatsApp) and acquisition channels (landing page, display ads). "
  },
  {
    q: "How often do I need to iterate messaging?",
    a: "It depends on your product type, ideal purchase frequency, and cohort type. We'll learn the best timing and automatically update the message accordingly."
  },
  {
    q: "How do you predict content performance?",
    a: "We consider historical performance, matching brand tone and audience, and other factual correctness.",
  },
  {
    q: "Which CRM platforms and data formats do you support to integrate?",
    a: "We support Hubspot, Salesforce, and CSV/excel files.",
  },
  {
    q: "Is my training data secure?",
    a: "Your training data is securely stored on our server and accessible exclusively on your private pages. All data transfers are encrypted to protect your privacy."
  },
  {
    q: "Who owns the training data?",
    a: "You retain the ownership when you use the dataset to train the model. Data from external providers is owned by us or those providers.",
  },
  {
    q: "Do you use personal data?",
    a: "No, we don't. For the personalization, we optimize messaging with field tags such as { first_name } without the actual PII values, and all the data is contextualized further with categorical data such as cohort type."
  },
]


export const Home = () => {
  const [activeMenuId, setActiveMenuId] = useState(0)

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, interactive-widget=resizes-content" />
        <link rel="canonical" href="https://versi0n.io/" />
        <title>Home | version</title>
      </Helmet>

      <Header />

      <div className="content">
        <div className="container" style={{ maxHeight: "800px", maxWidth: "1200px", padding: "100px 50px", display: "flex", flexDirection: "column", alignItems: "center" }}>

          <NewsBar newsTitle={"Currently on the Accelerator Program with AWS"} link={"https://www.linkedin.com/in/k-i-i/"} />

          <h1 style={{ fontSize: "45px", lineHeight: 1.5, fontWeight: 700, padding: "0 0 10px 0" }}>
            Acquire <span style={{ color: "#00d1b2" }}>loyal customers</span> with
            <br />
            hyper-personalized messages
          </h1>

          <h2 style={{ fontSize: "30px", lineHeight: 1.1, padding: "0 0 30px 0" }}>
            Learn. Communicate. Convert with AI.
          </h2>

          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <button
              className="button is-dark"
              style={{ width: "250px", fontSize: "24px" }}
              onClick={e => { handleGtag(e, "home", userId); window.location.href = `${domain}/playground` }}
            >
              Try for free
            </button>
            <button
              className="button is-primary"
              style={{ marginLeft: "30px", fontSize: "24px", fontWeight: 700, width: "300px", }}
              onClick={e => { handleGtag(e, "home", userId); window.location.href = `${domain}/#custom-demo` }}
            >
              Book a custom demo
            </button>
          </div>
        </div>

        <div className="container" style={{ minHeight: "280px", width: "100%", minWidth: "100%", padding: "60px 0 40px 0", display: "flex", flexDirection: "column", alignItems: "center", }}>
          <h3>Built by a team that scaled growth at:</h3>
          <div className="container" style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
            {carouselCompanies.map((item, i) => {
              return (
                <img
                  key={i} src={item.logo} alt={item.alt} height={item.height} width={item.width} style={{ margin: "30px" }}
                />
              )
            })}
          </div>
        </div>

        <div className="container" id="how-it-works" style={{ padding: "120px 80px", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#fafafa", minWidth: "100%" }}>
          <h2>Drive user engagement in 3 steps</h2>
          <h4>Develop drip campaigns for each individual customer. Powered by AI.</h4>

          <div style={{ width: "1000px", marginTop: "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

            <div className="card" style={{ width: "1000px", margin: "30px 0", padding: "30px", borderRadius: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ borderRadius: "30px", backgroundColor: "#00d1b2", color: "#fafafa", height: "30px", width: "30px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", margin: "0 10px 0.8rem 0" }}>
                  1
                </div>
                <h4>Learn.</h4>
              </div>
              <hr style={{ backgroundColor: "#00d1b2", margin: "0 0 30px 0", height: "0.5px" }} />
              <div className="container" style={{ display: "flex", justifyContent: "center", }}>
                <div className="container" style={{ marginRight: "50px" }}>
                  <h5>Identify customer purchase intent.</h5>
                  <p>Through our CRM integration, we identify individual customer's preferences and purchase patterns.</p>
                </div>
                <div className="container">
                  <img src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728299986/pj_m_home/ilx7hcrrj7rzedkbx7lq.png" alt="Connect your go-to CRM tools to make data pipeline" />
                  <p style={{ fontSize: "7px" }}>Connect with CRM platform and make data pipeline.</p>
                </div>
              </div>
            </div>

            <div className="card" style={{ width: "1000px", margin: "30px 0", padding: "30px", borderRadius: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ borderRadius: "30px", backgroundColor: "#00d1b2", color: "#fafafa", height: "30px", width: "30px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", margin: "0 10px 0.8rem 0" }}>
                  2
                </div>
                <h4>Communicate.</h4>
              </div>
              <hr style={{ backgroundColor: "#00d1b2", margin: "0 0 30px 0", height: "0.5px" }} />
              <div className="container" style={{ display: "flex", justifyContent: "center", }}>
                <div className="container" style={{ marginRight: "50px" }}>
                  <h5>Communicate strategically.</h5>
                  <p>We design competitive communication strategies for each customer and start to send a message accordingly.</p>
                </div>
                <div className="container">
                  <img src="https://res.cloudinary.com/dfeirxlea/image/upload/t_width 300 x height auto/v1728302420/pj_m_home/xy58a7imyquuvkgukqxt.png" alt="Send hyper-personalized messages that capture their attentions" />
                </div>
              </div>
            </div>

            <div className="card" style={{ width: "1000px", margin: "30px 0", padding: "30px", borderRadius: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ borderRadius: "30px", backgroundColor: "#00d1b2", color: "#fafafa", height: "30px", width: "30px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", margin: "0 10px 0.8rem 0" }}>
                  3
                </div>
                <h4>Convert.</h4>
              </div>
              <hr style={{ backgroundColor: "#00d1b2", margin: "0 0 30px 0", height: "0.5px" }} />
              <div className="container" style={{ display: "flex", justifyContent: "center", }}>
                <div className="container" style={{ marginRight: "50px" }}>
                  <h5>Convert leads into loyal customers.</h5>
                  <p>
                    After each message is sent, we assess cohort retention performance against our predictions and adjust our communication accordingly.
                    <br />
                    Messaging is automatically updated following each iteration.
                  </p>
                </div>
                <div className="container">
                  <img src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728304264/pj_m_home/eesrfobwa33hbauv6po1.png" alt="Predict performance and keep iterating the communication startegies" />
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="container" id="use-cases" style={{ padding: "120px 80px", display: "flex", flexDirection: "column", alignItems: "center", minWidth: "1000px" }}>
          <h2>Leverage multiple touchpoints</h2>
          <h4>Send consistent, competitive messages throughout the customer journey.</h4>

          <div className="container" style={{ display: "flex", width: "900px", justifyContent: "space-between", margin: "70px 0 40px" }}>
            {useCases.map((item, i) => {
              const { title, backgroundImg, backgroundImgSelected, bottomPosition } = item
              return (
                <button key={i} style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "200px" }} onClick={() => setActiveMenuId(i)}>
                  <img src={activeMenuId === i ? backgroundImgSelected : backgroundImg} alt="background" width={240} height={"auto"} />
                  <h5 style={{ position: "relative", bottom: bottomPosition }}>
                    {title}
                  </h5>
                </button>
              )
            })}
          </div>

          {activeMenuId === 0 &&
            <div className="card" style={{ minWidth: "1000px", maxWidth: "1000px", minHeight: "500px", maxHeight: "500px", padding: "20px" }}>
              <p>
                <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
                An average consumer needs to purchase <span style={{ fontWeight: 700 }}>at least 3 times</span> to feel loyal to the service.
              </p>
              <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728396141/pj_m_home/tufwfayq34jiofjxmypp.png"
                  alt="received push notification" width={520} height={"auto"}
                  style={{ margin: "10px" }} />
                <div>
                  <p style={{ fontWeight: 700, fontSize: "24px" }}>170% Sales Increase</p>
                  <p>We predict individual customer needs and offer timely product recommendations.</p>
                </div>
              </div>
            </div>
          }

          {activeMenuId === 1 &&
            <div className="card" style={{ minWidth: "1000px", maxWidth: "1000px", minHeight: "500px", maxHeight: "500px", padding: "20px" }}>
              <p>
                <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
                <span style={{ fontWeight: 700 }}>93% of customers</span> will never return after a single disappointing experience.
              </p>
              <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728397449/pj_m_home/hdzmhwgofjvlpgnfoqwv.png"
                  width={500} height={"auto"} style={{ margin: "10px" }} alt="Engaging user"
                />
                <div>
                  <p style={{ fontWeight: 700, fontSize: "24px" }}>1.5x Customer Lifetime Value</p>
                  <p>Predict the churn risk real-time and proactively reach out with personalized offers.</p>
                </div>
              </div>
            </div>
          }

          {activeMenuId === 2 &&
            <div className="card" style={{ minWidth: "1000px", maxWidth: "1000px", minHeight: "500px", maxHeight: "500px", padding: "20px" }}>
              <p>
                <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
                Acquring loyal users costs <span style={{ fontWeight: 700 }}>25 times</span> more than retaining them.
              </p>
              <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728400881/pj_m_home/zhhwtfztyxtyysqbvkaf.png"
                  width={500} height={"auto"} style={{ margin: "10px" }} alt="Ad campaign"
                />
                <div>
                  <p style={{ fontWeight: 700, fontSize: "24px" }}>2x CTR Increase</p>
                  <p>Define loyal customers and develop targeted display ad campaigns.</p>
                </div>
              </div>
            </div>
          }

          <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "100px" }}>
            <button
              className="button is-dark"
              style={{ width: "250px", fontSize: "24px" }}
              onClick={e => { handleGtag(e, "home", userId); window.location.href = `${domain}/playground` }}
            >
              Try for free
            </button>
            <button
              className="button is-primary"
              style={{ marginLeft: "30px", fontSize: "24px", fontWeight: 700, width: "300px", }}
              onClick={e => { handleGtag(e, "home", userId); window.location.href = `${domain}/#custom-demo` }}
            >
              Book a custom demo
            </button>
          </div>
        </div>

        <div className="container has-background-dark" style={{ minWidth: "100%", minHeight: "500px", padding: "120px 80px", display: "flex", justifyContent: "center" }}>
          <video className="card" width="800px" height="auto" controls autoPlay muted>
            <source
              src="https://res.cloudinary.com/dfeirxlea/video/upload/v1722553637/pj_m_test/ktuinqli1wjq279xmnjz.mp4"
              type="video/mp4" />
          </video>
        </div>

        <div className="container" id="custom-demo" style={{ minHeight: "500px", padding: "120px 80px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2>Book a custom demo</h2>
          <p>30 - 45 mins - Consultation, demo, Q&A</p>
          <Suspense fallback={<Loading />}>
            <InlineWidget
              url="https://calendly.com/kuriko-i/custom-demo?hide_event_type_details=1&hide_gdpr_banner=1"
              styles={{ marginTop: "30px", width: "500px", height: "600px" }}
            />
          </Suspense>
        </div>

        <div className="container" id="faq" style={{ minHeight: "700px", width: "100%", minWidth: "100%", padding: "120px 80px", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#fafafa" }}>
          <h2>FAQ</h2>
          <div style={{ width: "800px", marginTop: "20px" }}>
            {faqList.map((item, i) => <Fragment key={i}><h6>{item.q}</h6><p>{item.a}</p><hr /></Fragment>)}
          </div>
        </div>

        <div className="container" id="contact-us" style={{ minHeight: "700px", padding: "120px 80px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2>Contact us</h2>
          <ContactForm />
        </div>

        <BackToTop />
      </div>

      <Footer />
    </HelmetProvider >
  )
}


export const MobileHome = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, interactive-widget=resizes-content" />
        <title>Home | version</title>
        <link rel="canonical" href="https://versi0n.io/" />
      </Helmet>

      <MobileHeader />

      <div className="content">
        <div className="container" style={{ maxHeight: "800px", maxWidth: "100%", padding: "50px 20px", display: "flex", flexDirection: "column", alignItems: "center" }}>

          <NewsBar newsTitle={"Currently on the Accelerator Program with AWS"} link={"https://www.linkedin.com/in/k-i-i/"} fontSize="12px" />

          <h1 style={{ fontSize: "36px", lineHeight: 1.5, fontWeight: 700, padding: "0 0 30px 0" }}>
            Acquire <span style={{ color: "#00d1b2" }}>loyal customers</span> with
            <br />
            hyper-personalized messages
          </h1>

          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <button
              className="button is-dark"
              style={{ width: "150px", fontSize: "20px" }}
              onClick={e => { handleGtag(e, "home", userId); window.location.href = `${domain}/playground` }}
            >
              Try for free
            </button>
            <button
              className="button is-primary"
              style={{ marginLeft: "5px", fontSize: "20px", fontWeight: 700, width: "220px", }}
              onClick={e => { handleGtag(e, "home", userId); window.location.href = `${domain}/#custom-demo` }}
            >
              Book a custom demo
            </button>
          </div>
        </div>

        <div className="container" style={{ minHeight: "280px", width: "100%", minWidth: "100%", padding: "30px 20px 20px", display: "flex", flexDirection: "column", alignItems: "center", }}>
          <h3>Built by a team that scaled growth at:</h3>
          <div className="container" style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginTop: "10px", flexWrap: "wrap" }}>
            {carouselCompanies.map((item, i) => {
              return (
                <img
                  key={i} src={item.logo} alt={item.alt} height={item.height / 1.5} width={item.width / 1.5} style={{ margin: "5px" }}
                />
              )
            })}
          </div>
        </div>


        <div className="container" id="how-it-works" style={{ padding: "60px 20px 30px", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#fafafa", minWidth: "100%" }}>
          <h2>Drive user engagement in 3 steps</h2>
          <h4>Develop drip campaigns for each individual customer. Powered by AI.</h4>

          <div className="content" style={{ width: "100%", marginTop: "5px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div className="card" style={{ width: "1000px", margin: "30px 0", padding: "30px", borderRadius: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ borderRadius: "30px", backgroundColor: "#00d1b2", color: "#fafafa", height: "30px", width: "30px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", margin: "0 10px 0.8rem 0" }}>
                  1
                </div>
                <h4>Learn.</h4>
              </div>
              <hr style={{ backgroundColor: "#00d1b2", margin: "0 0 30px 0", height: "0.5px" }} />
              <div className="container" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <div className="container" style={{ marginRight: "50px" }}>
                  <h5>Identify customer purchase intent.</h5>
                  <p>Through our CRM integration, we identify individual customer's preferences and purchase patterns.</p>
                </div>
                <div className="container" style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                  <img src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728299986/pj_m_home/ilx7hcrrj7rzedkbx7lq.png" alt="Connect your go-to CRM tools to make data pipeline" />
                </div>
              </div>
            </div>

            <div className="card" style={{ width: "1000px", margin: "30px 0", padding: "30px", borderRadius: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ borderRadius: "30px", backgroundColor: "#00d1b2", color: "#fafafa", height: "30px", width: "30px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", margin: "0 10px 0.8rem 0" }}>
                  2
                </div>
                <h4>Communicate.</h4>
              </div>
              <hr style={{ backgroundColor: "#00d1b2", margin: "0 0 30px 0", height: "0.5px" }} />
              <div className="container" style={{ display: "flex", justifyContent: "center", }}>
                <div className="container" style={{ marginRight: "50px" }}>
                  <h5>Communicate strategically.</h5>
                  <p>We design competitive communication strategies for each customer and start to send a message accordingly.</p>
                </div>
                <div className="container">
                  <img src="https://res.cloudinary.com/dfeirxlea/image/upload/t_width 300 x height auto/v1728302420/pj_m_home/xy58a7imyquuvkgukqxt.png" alt="Send hyper-personalized messages that capture their attentions" />
                </div>
              </div>
            </div>

            <div className="card" style={{ width: "1000px", margin: "30px 0", padding: "30px", borderRadius: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ borderRadius: "30px", backgroundColor: "#00d1b2", color: "#fafafa", height: "30px", width: "30px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "700", margin: "0 10px 0.8rem 0" }}>
                  3
                </div>
                <h4>Convert.</h4>
              </div>
              <hr style={{ backgroundColor: "#00d1b2", margin: "0 0 30px 0", height: "0.5px" }} />
              <div className="container" style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <div className="container">
                  <h5>Convert leads into loyal customers.</h5>
                  <p>
                    After each message is sent, we assess cohort retention performance against our predictions and adjust our communication accordingly.
                    <br />
                    Messaging is automatically updated following each iteration.
                  </p>
                </div>
                <div className="container" style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                  <img src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728304264/pj_m_home/eesrfobwa33hbauv6po1.png" alt="Predict performance and keep iterating the communication startegies" />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="container" id="use-cases" style={{ padding: "60px 20px 30px", display: "flex", flexDirection: "column", alignItems: "center", minWidth: "100%" }}>
          <h2>Leverage multiple touchpoints</h2>
          <h4>Send consistent, competitive messages.</h4>

          <button style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "140px", marginTop: "50px" }}>
            <img src={useCases[0].backgroundImg} alt="background" width={200} height={"auto"} />
            <h5 style={{ position: "relative", bottom: "110px" }}>
              {useCases[0].title}
            </h5>
          </button>
          <div className="card" style={{ minWidth: "350px", maxWidth: window.innerWidth <= 430 ? "400px" : "800px", padding: "20px" }}>
            <p>
              <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
              An average consumer needs to purchase <span style={{ fontWeight: 700 }}>at least 3 times</span> to feel loyal to the service.
            </p>
            <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
            <div style={{ display: "flex", alignItems: "center", flexDirection: window.innerWidth <= 430 ? "column" : "row" }}>
              <img
                src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728396141/pj_m_home/tufwfayq34jiofjxmypp.png"
                alt="received push notification" width={window.innerWidth <= 430 ? 520 : 350} height={"auto"}
                style={{ margin: "10px" }}
              />
              <div>
                <p style={{ fontWeight: 700, fontSize: "24px" }}>170% Sales Increase</p>
                <p>We predict individual customer needs and offer timely product recommendations.</p>
              </div>
            </div>
          </div>

          <button style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "140px", marginTop: "50px" }}>
            <img src={useCases[1].backgroundImg} alt="background" width={200} height={"auto"} />
            <h5 style={{ position: "relative", bottom: "110px" }}>
              {useCases[1].title}
            </h5>
          </button>
          <div className="card" style={{ minWidth: "350px", maxWidth: window.innerWidth <= 430 ? "400px" : "800px", padding: "20px" }}>
            <p>
              <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
              <span style={{ fontWeight: 700 }}>93% of customers</span> will never return after a single disappointing experience.
            </p>
            <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
            <div style={{ display: "flex", alignItems: "center", flexDirection: window.innerWidth <= 430 ? "column" : "row" }}>
              <img
                src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728397449/pj_m_home/hdzmhwgofjvlpgnfoqwv.png"
                alt="Engaging user" width={window.innerWidth <= 430 ? 520 : 350} height={"auto"}
                style={{ margin: "10px" }}
              />
              <div>
                <p style={{ fontWeight: 700, fontSize: "24px" }}>1.5x Customer Lifetime Value</p>
                <p>Predict the churn risk real-time and proactively reach out with personalized offers.</p>
              </div>
            </div>
          </div>

          <button style={{ display: "flex", alignItems: "center", flexDirection: "column", height: "140px", marginTop: "50px" }}>
            <img src={useCases[2].backgroundImg} alt="background" width={200} height={"auto"} />
            <h5 style={{ position: "relative", bottom: "110px" }}>
              {useCases[2].title}
            </h5>
          </button>
          <div className="card" style={{ minWidth: "350px", maxWidth: window.innerWidth <= 430 ? "400px" : "800px", padding: "20px" }}>
            <p>
              <span style={{ fontStyle: "italic", fontWeight: 700 }}>? Did you know: </span>
              Acquring loyal users costs <span style={{ fontWeight: 700 }}>25 times</span> more than retaining them.
            </p>
            <hr style={{ backgroundColor: "#00d1b2", marginTop: 0 }} />
            <div style={{ display: "flex", alignItems: "center", flexDirection: window.innerWidth <= 430 ? "column" : "row" }}>
              <img
                src="https://res.cloudinary.com/dfeirxlea/image/upload/v1728400881/pj_m_home/zhhwtfztyxtyysqbvkaf.png"
                alt="Ad campaign" width={window.innerWidth <= 430 ? 520 : 350} height={"auto"}
                style={{ margin: "10px" }}
              />
              <div>
                <p style={{ fontWeight: 700, fontSize: "24px" }}>2x CTR Increase</p>
                <p>Define loyal customers and develop targeted display ad campaigns.</p>
              </div>
            </div>
          </div>

          <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: "30px 0" }}>
            <button
              className="button is-dark"
              style={{ width: "150px", fontSize: "20px" }}
              onClick={e => { handleGtag(e, "home", userId); window.location.href = `${domain}/playground` }}
            >
              Try for free
            </button>
            <button
              className="button is-primary"
              style={{ marginLeft: "5px", fontSize: "20px", fontWeight: 700, width: "220px", }}
              onClick={e => { handleGtag(e, "home", userId); window.location.href = `${domain}/#custom-demo` }}
            >
              Book a custom demo
            </button>
          </div>
        </div>


        <div className="container has-background-dark" style={{ minWidth: "100%", padding: window.innerWidth <= 430 ? "50px 10px" : "50px", display: "flex", justifyContent: "center" }}>
          <video width="100%" height="auto" controls autoPlay muted>
            <source
              src="https://res.cloudinary.com/dfeirxlea/video/upload/v1722553637/pj_m_test/ktuinqli1wjq279xmnjz.mp4"
              type="video/mp4"
            />
          </video>
        </div>


        <div className="container" id="custom-demo" style={{ padding: "60px 20px 30px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2>Book a custom demo</h2>
          <p>30 - 45 mins - Consultation, demo, Q&A</p>
          <Suspense fallback={<Loading />}>
            <InlineWidget
              url="https://calendly.com/kuriko-i/custom-demo?hide_event_type_details=1&hide_gdpr_banner=1"
              styles={{ marginTop: "30px", width: "360px", height: "600px" }}
            />
          </Suspense>
        </div>

        <div className="container" id="faq" style={{ minHeight: "700px", width: "100%", minWidth: "100%", padding: "60px 20px 30px", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "#fafafa" }}>
          <h2>FAQ</h2>
          <div style={{ width: "100%", marginTop: "20px" }}>
            {faqList.map((item, i) => <Fragment key={i}><h6>{item.q}</h6><p>{item.a}</p><hr /></Fragment>)}
          </div>
        </div>

        <div className="container" id="contact-us" style={{ padding: "60px 20px 30px", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2>Contact us</h2>
          <ContactForm width={window.innerWidth <= 430 ? "360px" : undefined} />
        </div>

        <BackToTop padding={"10px"} />
      </div>

      <MobileFooter />
    </HelmetProvider >
  )
}