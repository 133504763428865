import { useRef, useState, useEffect, useCallback } from "react"
import { useLocation, useParams } from "react-router-dom"
import { FaInfoCircle } from "react-icons/fa"
import { Select } from "./Elements"
import { handleGtag } from "../utils/gtag"
import { returnSelfUserId } from "../../lib/.auth"
import { getFacebookInsights, createFacebookAd, retrieveFacebookAccessToken } from "../../lib/.api"
import "../../styles/main.scss"
var userId = returnSelfUserId()
var defaultAdAccountId = "10154011050315635"
var clientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID
var clientSecret = process.env.REACT_APP_FACEBOOK_CLIENT_SECRET
var validations = [
  {
    value: "OUTCOME_LEADS",
    title: "Generate leads",
    details: [
      { title: "Leads to ad", value: "LEAD_GENERATION", promotedObject: "page_id", destinationType: "ON_AD" },
      { title: "Leads to Messanger", value: "LEAD_GENERATION", promotedObject: "page_id", destinationType: "LEAD_FROM_MESSENGER" },
      { title: "Leads to Instagram", value: "LEAD_GENERATION", promotedObject: "page_id", destinationType: "LEAD_FROM_IG_DIRECT" },
      { title: "Leads to phone call", value: "QUALITY_CALL", promotedObject: "page_id", destinationType: "PHONE_CALL" },

      { title: "Conversions (pixel)", value: "OFFSITE_CONVERSION", promotedObject: "pixel_id", destinationType: null, },
      { title: "Link clicks (pixel)", value: "LINK_CLICKS", promotedObject: "pixel_id", destinationType: null, },
      { title: "Reach (pixel)", value: "REACH", promotedObject: "pixel_id", destinationType: null, },
      { title: "Landing page views (pixel)", value: "LANDING_PAGE_VIEWS", promotedObject: "pixel_id", destinationType: null, },
      { title: "Impressions (pixel)", value: "IMPRESSIONS", promotedObject: "pixel_id", destinationType: null, },
    ],
  },
  {
    value: "OUTCOME_AWARENESS",
    title: "Improve brand awareness",
    details: [
      { title: "Ad recall lift", value: "AD_RECALL_LIFT", promotedObject: "page_id", destinationType: null },
      { title: "Reach", value: "REACH", promotedObject: "page_id", destinationType: null },
      { title: "Impressions", value: "IMPRESSIONS", promotedObject: "page_id", destinationType: null },
      { title: "Video thruplay", value: "THRUPLAY", promotedObject: "page_id", destinationType: null },
      { title: "Video continuous views", value: "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS", promotedObject: "page_id", destinationType: null },
      { title: "Store visit", value: "REACH", promotedObject: "place_page_set_id", destinationType: null },
    ]
  },
  {
    value: "OUTCOME_TRAFFIC",
    title: "Drive website traffic",
    details: [
      { title: "Link clicks", value: "LINK_CLICKS", promotedObject: "application_id", destinationType: null },
      { title: "Reach", value: "REACH", promotedObject: "application_id", destinationType: null },
      { title: "Landing page views", value: "LANDING_PAGE_VIEWS", promotedObject: null, destinationType: null },
      { title: "Impressions", value: "IMPRESSIONS", promotedObject: null, destinationType: null },
      { title: "Link clicks (Messanger)", value: "LINK_CLICKS", promotedObject: null, destinationType: "MESSENGER" },
      { title: "Reach (Messanger)", value: "REACH", promotedObject: null, destinationType: "MESSENGER" },
      { title: "Impressions (Messanger)", value: "IMPRESSIONS", promotedObject: null, destinationType: "MESSENGER" },
      { title: "Link clicks (WhatsApp)", value: "LINK_CLICKS", promotedObject: "page_id", destinationType: "WHATSAPP" },
      { title: "Reach (WhatsApp)", value: "REACH", promotedObject: "page_id", destinationType: "WHATSAPP" },
      { title: "Impressions (WhatsApp)", value: "IMPRESSIONS", promotedObject: "page_id", destinationType: "WHATSAPP" },
      { title: "Phone call", value: "QUALITY_CALL", promotedObject: null, destinationType: "PHONE_CALL" },
      { title: "Link clicks (Phone call)", value: "LINK_CLICKS", promotedObject: null, destinationType: "PHONE_CALL" },
    ],
  },
  {
    value: "OUTCOME_ENGAGEMENT",
    title: "Increase engagement",
    details: [
      { title: "Post engagement", value: "POST_ENGAGEMENT", promotedObject: null, destinationType: "ON_POST" },
      { title: "Post reach", value: "REACH", promotedObject: null, destinationType: "ON_POST" },
      { title: "Post impressions", value: "IMPRESSIONS", promotedObject: null, destinationType: "ON_POST" },
      { title: "Facebook page likes", value: "PAGE_LIKES", promotedObject: "page_id", destinationType: "ON_PAGE" },
      { title: "Event response", value: "EVENT_RESPONSE", promotedObject: null, destinationType: "ON_EVENT" },
      { title: "Event post engagement", value: "POST_ENGAGEMENT", promotedObject: null, destinationType: "ON_EVENT" },
      { title: "Event reach", value: "REACH", promotedObject: null, destinationType: "ON_EVENT" },
      { title: "Event page impressions", value: "IMPRESSIONS", promotedObject: null, destinationType: "ON_EVENT" },

      { title: "Video thruplay", value: "THRUPLAY", promotedObject: null, destinationType: "ON_VIDEO" },
      { title: "Video continuous views", value: "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS", promotedObject: null, destinationType: "ON_VIDEO" },

      { title: "Messanger conversion", value: "CONVERSIONS", promotedObject: "page_id", destinationType: "MESSENGER" },
      { title: "Messanger link clicks", value: "LINK_CLICKS", promotedObject: "page_id", destinationType: "MESSENGER" },
      { title: "Messanger lead generation", value: "LEAD_GENERATION", promotedObject: "page_id", destinationType: "MESSENGER" },

      { title: "Conversions (pixel)", value: "OFFSITE_CONVERSION", promotedObject: "pixel_id", destinationType: null, },
      { title: "Link clicks (pixel)", value: "LINK_CLICKS", promotedObject: "pixel_id", destinationType: null, },
      { title: "Reach (pixel)", value: "REACH", promotedObject: "pixel_id", destinationType: null, },
      { title: "Landing page views (pixel)", value: "LANDING_PAGE_VIEWS", promotedObject: "pixel_id", destinationType: null, },
      { title: "Impressions (pixel)", value: "IMPRESSIONS", promotedObject: "pixel_id", destinationType: null, },
    ]
  },
  {
    value: "OUTCOME_APP_PROMOTION",
    title: "Promote app installs",
    details: [
      { title: "App install", value: "APP_INSTALLS", promotedObject: "application_id", destinationType: null },
      { title: "Link clicks", value: "LINK_CLICKS", promotedObject: "application_id", destinationType: null },
      { title: "Offsite conversion", value: "OFFSITE_CONVERSIONS", promotedObject: "application_id", destinationType: null },
    ]
  },
  {
    value: "OUTCOME_SALES",
    title: "Drive sales",
    details: [
      { title: "Conversions (pixel)", value: "OFFSITE_CONVERSION", promotedObject: "pixel_id", destinationType: null, },
      { title: "Conversions (via messanger)", value: "CONVERSIONS", promotedObject: "page_id", destinationType: "MESSENGER" },
      { title: "Phone call", value: "QUALITY_CALL", promotedObject: "page_id", destinationType: "PHONE_CALL" },
      { title: "Website link click", value: "LINK_CLICKS", promotedObject: "product_catalog_id", destinationType: "WEBSITE" },
    ]
  },
] // https://developers.facebook.com/docs/marketing-api/reference/ad-campaign-group#odax
var buyingTypes = [
  { value: "AUCTION", title: "Auction" },
]
var billingEvents = [
  { value: "IMPRESSIONS", title: "Impressions" },
  { value: "LINK_CLICKS", title: "Link clicks" },
  { value: "PAGE_LIKES", title: "Page likes" },
  { value: "POST_ENGAGEMENT", title: "Post engagement" },
  { value: "VIDEO_VIEWS", title: "Video views" },
] // https://developers.facebook.com/docs/marketing-api/bidding/overview/billing-events
const currencyOptions = [
  { value: "USD", title: "US$" },
  { value: "CAD", title: "CA$" },
  { value: "GBP", title: "£" },
  { value: "SGD", title: "SG$" },
  { value: "RM", title: "RM" },
]


export const FacebookAdForm = ({ adAccountId = defaultAdAccountId, ...props }) => {
  const { companyId } = useParams(); const location = useLocation()
  const [refreshToken, setRefreshToken] = useState(false)
  const [accessToken, setAccessToken] = useState(process.env.REACT_APP_FACEBOOK_ACCESS_TOKEN)
  const [campaignId, setCampaignId] = useState(null)
  const [adSetId, setAdSetId] = useState(null)
  const [adId, setAdId] = useState(null)
  const [campaigns, setCampaigns] = useState([])
  const [optimizationOptions, setOptimizationOptions] = useState([])
  const [isCreating, setIsCreating] = useState(false)
  const [error, setError] = useState(null)
  let name = useRef(null)
  let objective = useRef(validations[0]["value"])
  let optimizationGoal = useRef(null)
  let currency = useRef("USD")
  let dailyBudget = useRef(100)
  let gender = useRef("Default")
  let ageMin = useRef(22)
  let ageMax = useRef(65)
  let country = useRef("US")
  let city = useRef(null)
  const call = useCallback(() => {
    setOptimizationOptions(validations.find(item => item.value === objective.current)["details"])
  }, [])

  useEffect(() => {
    if (refreshToken) {
      retrieveFacebookAccessToken(companyId, clientId, clientSecret)
        .then(res => {
          if (res.status === 200) {
            const fbAuthCode = location.state && location.state.fbAuthCode ? location.state.fbAuthCode : null
            // add execution to renew access token
            setAccessToken(res.data); setRefreshToken(false)
          } //!FIXME
          else { setRefreshToken(false); return; }
        })
        .catch(err => { console.log(err); setRefreshToken(false); return; })
    }
  }, [companyId, refreshToken, location.state])

  useEffect(() => {
    if (campaigns.length === 0) {
      getFacebookInsights(adAccountId, accessToken)
        .then(res => {
          console.log("useef", res)
          if (res.status === 400) setRefreshToken(true);
          else if (res.status !== 200) return;
          else setCampaigns(res.data)
        })
        .catch(err => { return; })
    }
  }, [adAccountId, campaigns.length, accessToken])


  const handleAdSetCreation = e => {
    e.preventDefault(); setIsCreating(true); setError(null); handleGtag(e, "create fb campaign", userId);
    const formDataForCampaign = {
      access_token: accessToken,
      name: name.current ? name.current : `A new campaign created via versi0n.io`,
      objective: objective.current ? objective.current : validations[0]["value"],
      buying_type: buyingTypes[0]["value"],
      special_ad_categories: ["NONE"],
      status: "PAUSED"
    }
    createFacebookAd(adAccountId, "campaigns", formDataForCampaign)
      .then(res => {
        console.log("execution started", res)
        if (res.status === 400 && res.data.error.type === "OAuthException") { setRefreshToken(true) }
        else if (res.status !== 200) { console.log(res); setError("Server error"); setIsCreating(false) }
        else { setCampaignId(res.data.id); return res.data.id }
      })
      .then(campaignId => {
        const formDataForAdSet = {
          access_token: accessToken,
          campaign_id: campaignId,
          name: `${campaignId}-ad-set`,
          billing_event: billingEvents[0].value,
          daily_budget: Number(dailyBudget.current) * 100,
          currency: currency.current,
          optimization_goal: optimizationGoal.current,
          destination_type: validations.find(item => item.value === objective.current)?.details.find(v => v.value === optimizationGoal.current)?.destinationType,
          status: "PAUSED",
          targeting: {
            geo_locations: {
              countries: [country.current,],
              cities: city.current ? [city.current,] : []
            },
            genders: gender.current,
            age_min: Number(ageMin.current),
            age_max: Number(ageMax.current),
          }
        }
        createFacebookAd(adAccountId, "adsets", formDataForAdSet)
          .then(res => {
            if (res.status !== 200) { console.log(res); setError("Server error"); setIsCreating(false) }
            else { setAdSetId(res.data.id); return res.data.id }
          })
          .then(adSetId => {
            createFacebookAd(adAccountId, "ads", {
              access_token: accessToken, name: `Ad: Ad set ID: ${adSetId}`, adset_id: adSetId, creative: { creative_id: "YOUR_AD_CREATIVE_ID" }, status: "PAUSED"
            })
          })
          .then(res => {
            if (res.status !== 200) { console.log(res); setError("Server error"); setIsCreating(false) }
            else { setAdId(res.data.id); setIsCreating(false) }
          })
          .catch(err => { console.log(err); setError("Something went wrong."); setIsCreating(false) })
      })
      .catch(err => {
        if (err.response.status === 400 && err.response.data.error.type === "OAuthException") { setRefreshToken(true) }
        setError("Something went wrong."); setIsCreating(false)
      })
  }

  if (adSetId && adId) {
    return (
      <div className="container">
        <div style={{ width: "600px", padding: "20px" }}>
          <p>Campaign ID: {campaignId}</p>
          <p>Ad Set ID: {adSetId}</p>
          <p>Ad ID: {adId}</p>
          <a href="https://adsmanager.facebook.com/adsmanager/manage/campaigns?act=10154011050315635&business_id=597471700695681&global_scope_id=597471700695681&nav_entry_point=am_local_scope_selector&date=2021-07-26_2024-08-02%2Cmaximum&breakdown_regrouping=true" ariaBraillelabel="Facebook ad manager" target="_blank" rel="noopener noreferrer">
            Facebook ad manager
          </a>
        </div>
      </div>
    )
  }
  return (
    <div className="container">
      <div style={{ width: "600px", padding: "20px" }}>
        {
          campaigns?.length > 0 &&
          <div>
            <div style={{ width: "500px", display: "flex", alignItems: "center", margin: "20px 0" }}>
              <p className="label" style={{ width: "160px", minWidth: "160px", maxWidth: "160px" }}>Select a campaign</p>
              <div className={isCreating ? "select is-loading" : "select"}
                style={{ width: "350px", minWidth: "350px", maxWidth: "350px" }}>
                <select
                  style={{ width: "350px", minWidth: "350px", maxWidth: "350px" }}
                  onClick={e => { e.preventDefault(); setCampaignId(campaigns[e.target.selectedIndex]["id"]) }}
                >
                  {campaigns?.map((item, i) => { return (<option key={i}>{item}</option>) })}
                </select>
              </div>
            </div>

            <fieldset style={{ width: "100%" }}><legend>or</legend></fieldset>
          </div>
        }

        <div style={{ width: "500px", display: "flex", alignItems: "center", margin: "20px 0" }}>
          <p className="label" style={{ width: "160px", minWidth: "160px", maxWidth: "160px" }}>Title</p>
          <input
            type="text" className="input" style={{ width: "350px", minWidth: "350px", maxWidth: "350px" }}
            placeholder={"Your campaign title (you can change it later)"}
            onChange={e => name.current = e.target.value}
          />
        </div>

        <div style={{ width: "500px", display: "flex", alignItems: "center", margin: "20px 0" }}>
          <p className="label" style={{ width: "160px", minWidth: "160px", maxWidth: "160px" }}>Objective</p>
          <div className={isCreating ? "select is-loading" : "select"} style={{ width: "350px", minWidth: "350px", maxWidth: "350px" }} >
            <Select options={validations} currentSelectedItem={objective} callBack={call} />
          </div>
        </div>

        <div style={{ width: "500px", display: "flex", alignItems: "center", margin: "20px 0" }}>
          <p className="label" style={{ width: "160px", minWidth: "160px", maxWidth: "160px" }}>Daily budget</p>
          <input
            type="text" className="input" style={{ width: "250px", minWidth: "250px", maxWidth: "250px" }}
            defaultValue={100}
            onChange={e => dailyBudget.current = e.target.value}
          />
          <div className={isCreating ? "select is-loading" : "select"} style={{ width: "100px", minWidth: "100px", maxWidth: "100px" }} >
            <Select options={currencyOptions} currentSelectedItem={currency} width="100px" />
          </div>
        </div>

        <div style={{ width: "500px", display: "flex", alignItems: "center", margin: "20px 0" }}>
          <p className="label" style={{ width: "160px", minWidth: "160px", maxWidth: "160px" }}>Maximize&nbsp;<FaInfoCircle /></p>
          <div className={isCreating ? "select is-loading" : "select"} style={{ width: "350px", minWidth: "350px", maxWidth: "350px" }} >
            <Select options={optimizationOptions} currentSelectedItem={optimizationGoal} />
          </div>
        </div>

        <div style={{ width: "500px", display: "flex", margin: "20px 0" }}>
          <p className="label" style={{ width: "160px", minWidth: "160px", maxWidth: "160px", margin: "10px 0" }}>
            Target audience
          </p>

          <div>
            <div style={{ width: "350px", display: "flex", alignItems: "center", margin: "10px 0" }}>
              <p style={{ width: "80px", maxWidth: "80px", minWidth: "80px", marginRight: "10px" }}>Country</p>
              <input
                type="text" className="input" style={{ width: "260px", minWidth: "260px", maxWidth: "260px" }}
                defaultValue={"US"}
                onChange={e => country.current = e.target.value}
              />
            </div>

            <div style={{ width: "350px", display: "flex", alignItems: "center", margin: "10px 0" }}>
              <p style={{ width: "80px", maxWidth: "80px", minWidth: "80px", marginRight: "10px" }}>City</p>
              <input
                type="text" className="input" style={{ width: "260px", minWidth: "260px", maxWidth: "260px" }}
                onChange={e => city.current = e.target.value}
              />
            </div>

            <div style={{ width: "350px", display: "flex", alignItems: "center", margin: "10px 0" }}>
              <p style={{ width: "80px", maxWidth: "80px", minWidth: "80px", marginRight: "10px" }}>Gender</p>
              <div className={isCreating ? "select is-loading" : "select"} style={{ width: "260px", minWidth: "260px", maxWidth: "260px" }} >
                <Select options={[{ title: "Female", value: 2 }, { title: "Male", value: 1 }, { title: "All", value: "Default" },]} currentSelectedItem={gender} width="260px" />
              </div>
            </div>

            <div style={{ width: "350px", display: "flex", alignItems: "center", margin: "10px 0" }}>
              <p style={{ width: "80px", maxWidth: "80px", minWidth: "80px", marginRight: "10px" }}>Age</p>
              <input
                type="text" className="input" style={{ width: "130px", minWidth: "130px", maxWidth: "130px" }}
                defaultValue={"22"}
                placeholder="Min age"
                onChange={e => ageMin.current = e.target.value}
              />
              <input
                type="text" className="input" style={{ width: "130px", minWidth: "130px", maxWidth: "130px" }}
                defaultValue={"55"}
                placeholder="Max age"
                onChange={e => ageMax.current = e.target.value}
              />
            </div>

          </div>
        </div>

        <div style={{ width: "500px", margin: "20px 0" }}>
          <button className={isCreating ? "button is-primary is-loading" : "button is-primary"} style={{ width: "120px", marginLeft: "10px" }} onClick={e => handleAdSetCreation(e)}>
            Ship
          </button>
          {error && <p style={{ color: "red", fontSize: "16px" }}>{error}</p>}
          {isCreating && <p style={{ fontSize: "16px" }}>Takes about 5 sec</p>}
        </div>
      </div>
    </div>
  )
}


// https://graph.facebook.com/v20.0/CAMPAIGN_ID/insights
// https://developers.facebook.com/docs/marketing-api/insights#request
// ref. lead ad creation - https://developers.facebook.com/docs/marketing-api/guides/lead-ads/create
// ref. validations - https://developers.facebook.com/docs/marketing-api/reference/ad-campaign-group#odax
// ref. validations (legacy) - https://developers.facebook.com/docs/marketing-api/bidding/overview
// ref. target audience - https://developers.facebook.com/docs/marketing-api/audiences/reference/basic-targeting#demographics
// const destinationTypes = [
//   { value: "UNDEFINED", title: "No defined type" }, { value: "APP", title: "App store or app" }, { value: "APPLINKS_AUTOMATIC", title: "Automatic app link" }, { value: "FACEBOOK", title: "Facebook Marketplace" }, { value: "INSTAGRAM_DIRECT", title: "Instagram DM" }, { value: "INSTAGRAM_PROFILE", title: "Instagram feed" }, { value: "MESSAGING_INSTAGRAM_DIRECT_MESSENGER", title: "IG DM or Messenger" }, { value: "MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP", title: "IG DM, Messenger, WhatsApp" }, { value: "MESSAGING_INSTAGRAM_DIRECT_WHATSAPP", title: "IG DM, WhatsApp" }, { value: "MESSAGING_MESSENGER_WHATSAPP", title: "Messenger, WhatsApp" }, { value: "MESSENGER", title: "Messenger" }, { value: "ON_AD", title: "Ad" }, { value: "ON_POST", title: "Post" }, { value: "ON_VIDEO", title: "Video" }, { value: "ON_PAGE", title: "Page" }, { value: "ON_EVENT", title: "Engagement" }, { value: "PHONE_CALL", title: "Phone call" }, { value: "SHOP_AUTOMATIC", title: "Shop" }, { value: "WEBSITE", title: "Website" }, { value: "WHATSAPP", title: "WhatsApp" },
// ] // https://developers.facebook.com/docs/marketing-api/adset/destination_type/