export function modify(doc, h1Text, h2Text, imgSrc = null, imgSrcBase64 = null, imgIndex = 3) {
  if (doc) {
    const h1 = doc.querySelector("h1"); h1.innerText = h1Text
    const h2 = doc.querySelector("h2"); h2.innerText = h2Text
    const imgs = doc.querySelectorAll("img")
    // const heroImg = document.getElementsByClassName("*Hero*")
    // imgs.find(item => "Hero" in item || "hero" in item)
    const applicableImgs = [imgs[imgIndex], imgs[imgIndex + 1]]

    if (imgSrc) {
      applicableImgs.forEach(item => {
        item.src = imgSrc; item.srcset = `${imgSrc} 1200w, ${imgSrc} 300w, ${imgSrc} 1024w, ${imgSrc} 600w`
      })
    }
    else if (imgSrcBase64) {
      applicableImgs.forEach(item => {
        item.setAttribute('src', `data:image/png;base64,${imgSrcBase64}`);
        item.setAttribute("srcset", `data:image/png;base64,${imgSrcBase64} 1200w, data:image/png;base64,${imgSrcBase64} 300w, data:image/png;base64,${imgSrcBase64} 1024w, data:image/png;base64,${imgSrcBase64} 600w,`)
      })
    }
    return doc
  }
}