import { useState, useRef } from "react"
import { Loading } from "./Message"
import { handleGtag } from "../utils/gtag"
import { generateTextVariations, predictPerformance } from "../../lib/.api"
import { returnSelfUserId } from "../../lib/.auth"
import "../../styles/main.scss"
var textAgents = [
  { name: "FM1", description: "" },
  { name: "FM2", description: "" },
  { name: "FM3", description: "" },
  { name: "FM4", description: "" },
]
var userId = returnSelfUserId()


export const TextAgentInterface = (
  {
    publicDemoMode = true,
    companyId = null,
    productId = null,
    productName = null,
    description = null,
    direction = null,
    setWinnerText,
    ...props
  }) => {
  const [textAgentId, setTextAgentId] = useState(0); let product = useRef({})
  const [isGenerating, setIsGenerating] = useState(false); const [textError, setTextError] = useState(null)
  const [textVariations, setTextVariations] = useState([])
  const [selectedTexts, setSelectedTexts] = useState([])
  const [isSimulating, setIsSimulating] = useState(false)
  const [scores, setScores] = useState([]); const [scoreError, setScoreError] = useState(null)
  const [winnerTextId, setWinnerTextId] = useState(null)


  const handleTextVariation = e => {
    e.preventDefault(); handleGtag(e, "text agent interface", userId); setIsGenerating(true);
    const formData = publicDemoMode
      ? { agentId: textAgentId, productName, description, direction }
      : { agentId: textAgentId, companyId, productId }
    generateTextVariations(formData)
      .then(res => {
        if (res.status !== 200) { setTextError("Server error"); setIsGenerating(false); return }
        else { setTextVariations(res.data.variations); product.current = { ...res.data.product }; setIsGenerating(false); }
      })
      .catch(err => { setTextError("I am busy. Try another agent."); setIsGenerating(false); })
  }

  const handlePerformanceSimulation = e => {
    e.preventDefault(); handleGtag(e, "text agent interface", userId); if (selectedTexts.length === 0) return;

    setIsSimulating(true);
    const formData = { selectedTexts, product: { ...product.current } }
    predictPerformance(formData)
      .then(res => {
        if (res.status !== 200) { setScoreError("Something went wrong."); setIsSimulating(false) }
        else { setScores(res.data["performance"]); setWinnerTextId(res.data["winner_text_id"]); setWinnerText(res.data["winner_text"]); setIsSimulating(false) }
      })
      .catch(err => { setScoreError("Something went wrong."); setIsSimulating(false) })

  }


  return (
    <div className="container" style={{ display: "flex", width: "1200px", minWidth: "1200px", maxWidth: "1200px" }}>
      <form className="box" style={{ width: "1000px", minHeight: "100px", padding: "70px" }}>
        <label className="label">Text Agent - Inference API</label>

        <div className="radios" style={{ display: "flex", alignItems: "center", margin: "10px" }}>
          {textAgents.map((item, i) => {
            return (
              <label key={i} className="radio" style={{ margin: "10px 10px 10px 0px", display: "flex", fontSize: "14px" }}>
                <input type="radio" name="message" style={{ width: "15px", height: "15px", marginRight: "5px" }} checked={Boolean(i === textAgentId)} onChange={() => setTextAgentId(i)} />
                {item.name}
              </label>
            )
          })}

          <button className={isGenerating ? "button is-primary is-loading" : "button is-primary"} style={{ marginLeft: "auto" }} disabled={publicDemoMode ? textVariations.length > 0 : false} onClick={e => handleTextVariation(e)}>
            Generate variations
          </button>

        </div>
        {textError && <p style={{ color: "red", fontSize: "16px" }}>{textError}</p>}


        {textVariations.length > 0 &&
          <div className="container" style={{ margin: "20px 0 10px 0", }}>

            <div className="has-background-white-bis" style={{ padding: "10px", width: "900px" }}>
              {
                scores.length > 0
                  ?
                  <div className="container" style={{ height: "40px", width: "780px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <p style={{ fontSize: "10px", width: "45px", margin: "0 5px" }}>Overall score</p>
                    <p style={{ fontSize: "10px", width: "45px", margin: "0 5px" }}>Brand tone</p>
                    <p style={{ fontSize: "10px", width: "45px", margin: "0 5px" }}>Audience capture</p>
                    <p style={{ fontSize: "10px", width: "45px", margin: "0 5px" }}>Factual correctness</p>
                  </div>
                  :
                  <div className="container" style={{ height: "40px", width: "780px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}></div>
              }

              {isGenerating ? <Loading /> : textVariations.map((item, i) => {
                return (
                  <div key={i} className="container" style={{ margin: "5px 0", padding: "10px", border: winnerTextId !== null && Number(winnerTextId) === i ? "1.4px solid #B9005B" : "1px solid #dedede", width: "850px", height: "100px", display: "flex", alignItems: "center" }}>
                    <label className="checkbox" style={{ display: "flex", alignItems: "center", width: "600px", maxWidth: "600px", minWidth: "600px" }}>
                      <input type="checkbox" style={{ width: "20px", minWidnth: "20px", maxWidth: "20px", minHheight: "20px", minHeight: "20px", maxHeight: "20px", marginRight: "10px", fontSize: "12px" }}
                        onClick={e => {
                          var idSelected = selectedTexts.indexOf(i)
                          if (e.target.checked && idSelected === -1) setSelectedTexts([...selectedTexts, { id: i, text: item }])
                          else if (!e.target.checked && idSelected > -1) { selectedTexts.splice(i, 1); setSelectedTexts([...selectedTexts]) }
                        }} />
                      {item}
                    </label>
                    {
                      isSimulating
                        ? <Loading style={{ width: "100px", marginLeft: "20px" }} />
                        : scores.length > 0 && scores.find(item => Number(item.id) === i) !== undefined
                          ? scores.find(item => Number(item.id) === i)["scores"].map((v, int) => {
                            return (
                              <div key={int} style={{ backgroundColor: int === 0 ? "#B9005B" : "#fafafa", width: "30px", height: "30px", borderRadius: "30px", margin: "10px", display: "flex", justifyContent: "center", alignItems: "center", color: int === 0 ? "#fafafa" : "#191b1b" }}>
                                {v}
                              </div>
                            )
                          })
                          : <></>
                    }
                  </div>
                )
              })}

              <button
                className={isSimulating ? "button is-dark is-loading" : "button is-dark"} style={{ marginRight: "10px" }}
                disabled={publicDemoMode ? Boolean(selectedTexts.length === 0 || scores.length > 0) : false} onClick={e => handlePerformanceSimulation(e)}
              >
                Simulate performance
              </button>
              {scoreError && <p style={{ color: "red", fontSize: "16px" }}>{scoreError}</p>}

            </div>

          </div>
        }
      </form >
    </div>
  )
}