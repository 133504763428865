import { useRef, useState } from "react"
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md"
import { handleGtag } from "../utils/gtag"
import { sendEmail } from "../../lib/.api"
import { returnSelfUserId } from "../../lib/.auth"
var userId = returnSelfUserId()


export const ContactForm = ({ width = "700px", height = "300px", rows = 10, defaultMessage = null, ...props }) => {
  let name = useRef(""); let email = useRef(""); let message = useRef("")
  const [isSending, setIsSending] = useState(false); const [sent, setSent] = useState(false); const [error, setError] = useState(null)
  const sendMessage = e => {
    e.preventDefault(); setIsSending(true); handleGtag(e, "Contact us form submit")
    sendEmail({ user_id: userId !== undefined ? userId : null, name: name.current, email: email.current, message: message.current ? message.current : defaultMessage })
      .then(res => {
        if (res.status === 200) { setSent(true); name.current = ""; email.current = ""; message.current = ""; setIsSending(false) }
        else setError("Something went wrong.")
      })
      .catch(err => { setIsSending(false); setError(err) })
  }

  return (
    <div className="container" style={{ width: width, maxWidth: width, minHeight: height, padding: "10px" }}>
      {sent
        ?
        <p style={{ textAlign: "center" }}>Successfully sent. We will get back to you shortly.</p>
        :
        <div>
          <div className="container" style={{ display: "flex" }}>
            <input
              className="input"
              type="text" placeholder="John" style={{ marginRight: "10px" }}
              onChange={e => name.current = e.target.value}
            />
            <input
              className="input"
              type="email" placeholder="john@work.com"
              onChange={e => email.current = e.target.value}
            />
          </div>

          <textarea
            className="textarea"
            rows={rows} placeholder="Hello world" style={{ margin: "10px 0" }} defaultValue={defaultMessage}
            onChange={e => message.current = e.target.value}
          />

          <button className={isSending ? "button is-dark is-loading" : "button is-dark"} onClick={e => sendMessage(e)} style={{ width: "120px" }}>
            Send
            {/* &nbsp;<MdSend /> */}
          </button>

          {error &&
            <p style={{ color: "red", fontSize: "12px" }}>
              Something went wrong. If this error insists, contact us.
            </p>
          }
        </div>
      }
    </div>
  )
}


export const PaginationNav = ({ paginationItems = [], initPageId = 0, paginationDisabled, itemWidth = "150px", ...props }) => {
  const [currentPageId, setCurrentPageId] = useState(initPageId)

  return (
    <nav className="pagination is-centered" role="navigation" aria-label="pagination">
      <button
        className="pagination-previous" disabled={currentPageId === 0 || currentPageId === null || paginationDisabled}
        onClick={e => {
          e.preventDefault(); setCurrentPageId(currentPageId - 1);
          if (paginationItems[currentPageId - 1].link) { window.location.href = paginationItems[currentPageId - 1].link }
        }}
        style={{ width: "80px", maxWidth: "80px", minWidth: "80px", marginRight: props.margin ? props.margin : "100px" }}
      >
        <MdKeyboardDoubleArrowLeft />
      </button>

      <ul className="pagination-list" style={{ position: "relative" }}>
        {paginationItems.map((item, i) => {
          const { title, link } = item
          return (
            <li key={i} style={{ minWidth: itemWidth, maxWidth: itemWidth }}>
              <button
                className="pagination-link" style={{ height: "30px", minHeight: "30px", maxHeight: "30px", width: "30px", minWidth: "30px", maxWidth: "30px", borderRadius: "60px", backgroundColor: i === currentPageId ? "#B9005B" : "#fafafa", color: i === currentPageId ? "#fafafa" : undefined }}
                aria-label={item}
                onClick={e => {
                  e.preventDefault();
                  currentPageId < paginationItems.length ? setCurrentPageId(i) : setCurrentPageId(0);
                  if (link) { window.location.href = link }
                }}
                disabled={paginationDisabled}
              >
                {i + 1}
              </button>
              <p style={{ fontSize: "12px", marginTop: "10px", color: i === currentPageId ? "#B9005B" : undefined }}>{title}</p>
            </li>
          )
        })}
      </ul>

      <button
        className="pagination-next" disabled={currentPageId >= paginationItems.length - 1 || paginationDisabled}
        onClick={e => {
          e.preventDefault(); setCurrentPageId(currentPageId + 1);
          if (paginationItems[currentPageId + 1].link) { window.location.href = paginationItems[currentPageId + 1].link }
        }}
        style={{ width: "80px", maxWidth: "80px", minWidth: "80px", marginLeft: props.margin ? props.margin : "100px" }}
      >
        <MdKeyboardDoubleArrowRight />
      </button>

    </nav >
  )
}


export const FacebookAd = ({
  width = "300px",
  brandLogo,
  brandName,
  keyMessage,
  ctaMessage,
  ctaButton = "Explore",
  imgSrc = null,
  imgBase64 = null,
  landingUrl
}) => {
  const height = Number(width.replace("px", "")) * 525 / 310

  return (
    <div className="card" style={{ width: width, maxWidth: width, minWidth: width, maxHeight: `${height}px`, boxShadow: "5px 10px #414141" }}>
      <div style={{ width: "100%", height: "60px", minHeight: "60px", display: "flex", alignItems: "center", backgroundColor: "lightgrey", padding: "5px 10px" }}>
        <img src={brandLogo} alt="logo" style={{ height: "50px" }} />
        <div style={{ marginLeft: "20px", }}>
          <p style={{ fontSize: "16px", fontWeight: 700, color: "#191b1b" }}>{brandName}</p>
          <p style={{ fontSize: "12px", color: "grey" }}>Sponsored</p>
        </div>
      </div>

      <div style={{ padding: "5px 10px", minHeight: "80px", height: "80px", maxHeight: "80px", display: "flex", alignItems: "flex-start" }}>
        <p style={{ fontSize: "15px", lineHeight: 1.1, font: "arial" }}>
          {keyMessage && keyMessage.substring(0, 150) + "..."}
        </p>
      </div>

      <div style={{ height: width, width: "auto", backgroundColor: "#fafafa", display: "flex", alignItems: "center", justifyContent: "center" }}>
        {imgSrc
          ? <img src={imgSrc} alt="ad creative" style={{ width: "100%", height: "auto" }} />
          : imgBase64
            ? <img src={`data:image/png;base64,${imgBase64}`} alt="ad creative" style={{ width: "100%", height: "auto" }} />
            : <p style={{ color: "grey" }}>( - )</p>}
      </div>

      <div style={{ width: "100%", backgroundColor: "lightgrey", padding: "10px", height: "80px" }}>
        <p style={{ fontSize: "12px", color: "grey" }}>
          {landingUrl}
        </p>

        <div style={{ padding: "3px 5px 3px 3px", display: "flex", justifyContent: "center", width: "100%", height: "80px" }}>
          <p style={{ fontWeight: 700, fontSize: "16px", color: "#191b1b", }}>
            {ctaMessage}
          </p>
          <button className="button is-grey" style={{ marginLeft: "auto", width: "100px", padding: "5px 10px", height: "40px" }}>
            <p style={{ fontSize: "16px", fontWeight: 700 }}>
              {ctaButton}
            </p>
          </button>
        </div>
      </div>
    </div>

  )
}
